import type { Auth0Plugin } from '@auth0/auth0-vue'
import { createAuth0 } from '@auth0/auth0-vue'
import { config } from 'cfg'

const Auth0Config: Auth0Plugin = createAuth0({
  domain: config.auth0Domain,
  clientId: config.clientId,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: config.auth0Audience
  },
  cacheLocation: 'localstorage',
  useRefreshTokens: true
})

export default Auth0Config

export const getAccessToken = async () => {
  return await Auth0Config.getAccessTokenSilently()
}
