import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { computed, type ComputedRef, ref } from 'vue'

export const useDebugStore = defineStore('debug', () => {
  const _showNetworkFileMetadataFromStorage = useStorage<boolean>('showNetworkFileMetadata', false)
  const _showRouteSegmentsFromStorage = useStorage<boolean>('showRouteSegments', false)
  const _trackCoordinatesFromStorage = useStorage<boolean>('trackCoordinates', false)
  const _vehicleLengthFromStorage = useStorage<number | undefined>('vehicleLength', undefined)
  const _vehicleWidthFromStorage = useStorage<number | undefined>('vehicleWidth', undefined)
  const _vehicleHeightFromStorage = useStorage<number | undefined>('vehicleHeight', undefined)
  const _vehicleWeightFromStorage = useStorage<number | undefined>('vehicleWeight', undefined)
  const _startLatitudeFromStorage = useStorage<number | undefined>('startLatitude', undefined)
  const _startLongitudeFromStorage = useStorage<number | undefined>('startLongitude', undefined)
  const _endLatitudeFromStorage = useStorage<number | undefined>('endLatitude', undefined)
  const _endLongitudeFromStorage = useStorage<number | undefined>('endLongitude', undefined)
  const _networkIdFromStorage = useStorage<number | undefined>('networkId', undefined)

  const showNetworkFileMetadata = computed({
    get() {
      return _showNetworkFileMetadataFromStorage.value
        ? Boolean(_showNetworkFileMetadataFromStorage.value)
        : false
    },
    set(value: boolean) {
      _showNetworkFileMetadataFromStorage.value = value
    }
  })
  const showRouteSegments = computed<boolean>({
    get() {
      return _showRouteSegmentsFromStorage.value
        ? Boolean(_showRouteSegmentsFromStorage.value)
        : false
    },
    set(value: boolean) {
      _showRouteSegmentsFromStorage.value = value
    }
  })
  const trackCoordinates = computed<boolean>({
    get() {
      return _trackCoordinatesFromStorage.value
        ? Boolean(_trackCoordinatesFromStorage.value)
        : false
    },
    set(value: boolean) {
      _trackCoordinatesFromStorage.value = value
    }
  })
  const vehicleLength = computed<number | undefined>({
    get() {
      return _vehicleLengthFromStorage.value ? Number(_vehicleLengthFromStorage.value) : undefined
    },
    set(value?: number) {
      _vehicleLengthFromStorage.value = value
    }
  })
  const vehicleWidth = computed<number | undefined>({
    get() {
      return _vehicleWidthFromStorage.value ? Number(_vehicleWidthFromStorage.value) : undefined
    },
    set(value?: number) {
      _vehicleWidthFromStorage.value = value
    }
  })
  const vehicleHeight = computed<number | undefined>({
    get() {
      return _vehicleHeightFromStorage.value ? Number(_vehicleHeightFromStorage.value) : undefined
    },
    set(value?: number) {
      _vehicleHeightFromStorage.value = value
    }
  })
  const vehicleWeight = computed<number | undefined>({
    get() {
      return _vehicleWeightFromStorage.value ? Number(_vehicleWeightFromStorage.value) : undefined
    },
    set(value?: number) {
      _vehicleWeightFromStorage.value = value
    }
  })
  const startLatitude = computed<number | undefined>({
    get() {
      return _startLatitudeFromStorage.value ? Number(_startLatitudeFromStorage.value) : undefined
    },
    set(value?: number) {
      _startLatitudeFromStorage.value = value
    }
  })
  const startLongitude = computed<number | undefined>({
    get() {
      return _startLongitudeFromStorage.value ? Number(_startLongitudeFromStorage.value) : undefined
    },
    set(value?: number) {
      _startLongitudeFromStorage.value = value
    }
  })
  const endLatitude = computed<number | undefined>({
    get() {
      return _endLatitudeFromStorage.value ? Number(_endLatitudeFromStorage.value) : undefined
    },
    set(value?: number) {
      _endLatitudeFromStorage.value = value
    }
  })
  const endLongitude = computed<number | undefined>({
    get() {
      return _endLongitudeFromStorage.value ? Number(_endLongitudeFromStorage.value) : undefined
    },
    set(value?: number) {
      _endLongitudeFromStorage.value = value
    }
  })
  const networkId = computed<number | undefined>({
    get() {
      return _networkIdFromStorage.value ? Number(_networkIdFromStorage.value) : undefined
    },
    set(value?: number) {
      _networkIdFromStorage.value = value
    }
  })

  const isDebuggingEnabled: ComputedRef<boolean> = computed(
    () =>
      showNetworkFileMetadata.value ||
      showRouteSegments.value ||
      trackCoordinates.value ||
      !!vehicleLength.value ||
      !!vehicleWidth.value ||
      !!vehicleHeight.value ||
      !!vehicleWeight.value ||
      !!startLatitude.value ||
      !!startLongitude.value ||
      !!endLatitude.value ||
      !!endLongitude.value ||
      !!networkId.value
  )

  const latitude = ref<string>()
  const longitude = ref<string>()

  return {
    showNetworkFileMetadata,
    showRouteSegments,
    trackCoordinates,
    vehicleLength,
    vehicleWidth,
    vehicleHeight,
    vehicleWeight,
    startLatitude,
    startLongitude,
    endLatitude,
    endLongitude,
    networkId,
    isDebuggingEnabled,
    latitude,
    longitude
  }
})
