import { useConfirm } from 'primevue/useconfirm'
import type { ConfirmationServiceMethods } from 'primevue/confirmationservice'

let confirmDialog: ConfirmationServiceMethods

export function initConfirmations() {
  confirmDialog = useConfirm()
}

export function showConfirmationDialog(header: string, message: string, acceptLabel: string) {
  return new Promise<void>((resolve, reject) => {
    confirmDialog.require({
      group: 'confirmation',
      header: header,
      message: message,
      rejectClass: 'p-button-secondary p-button-outlined',
      rejectLabel: 'Annuleren',
      acceptLabel: acceptLabel,
      accept: () => resolve(),
      reject: () => reject()
    })
  })
}
