import type {
  GeoJsonLineFeature,
  GeoJsonPointFeature,
  PointProperties,
  Route
} from '@/contracts/route'
import { FeatureType, GeometryType } from '@/enums/route'
import type { POI } from '@/contracts/POI'
import { POIType } from '@/enums/POI-type'
import type { RoadWorks } from '@/contracts/road-works'
import atlas from 'azure-maps-control'

export function mapFeatures(route: Route): atlas.data.FeatureCollection {
  const startPointFeature: atlas.data.Feature<atlas.data.Geometry, PointProperties> =
    mapPointFeatureToFeature(route.startPointFeature)
  const features: atlas.data.Feature<atlas.data.Geometry, null>[] = mapLineFeaturesToFeatures(
    route.features
  )
  const endPointFeature: atlas.data.Feature<atlas.data.Geometry, PointProperties> =
    mapPointFeatureToFeature(route.endPointFeature)
  const POIFeatures: atlas.data.Feature<atlas.data.Geometry, PointProperties>[] = route.pois
    ? mapPOIsToFeatures(route.pois)
    : []
  const roadWorksFeatures: atlas.data.Feature<atlas.data.Geometry, PointProperties>[] =
    route.roadWorks ? mapRoadWorksToFeatures(route.roadWorks) : []

  const aggregatedFeatures: atlas.data.Feature<atlas.data.Geometry, PointProperties | null>[] = [
    startPointFeature,
    ...features,
    endPointFeature,
    ...POIFeatures,
    ...roadWorksFeatures
  ]

  return {
    type: route.type,
    features: aggregatedFeatures
  }
}

function mapPointFeatureToFeature(
  pointFeature: GeoJsonPointFeature
): atlas.data.Feature<atlas.data.Geometry, PointProperties> {
  return {
    type: FeatureType.Feature,
    geometry: {
      type: GeometryType.Point,
      coordinates: pointFeature.geometry.coordinates
    },
    properties: pointFeature.properties
  }
}

function mapLineFeaturesToFeatures(
  lineFeatures: GeoJsonLineFeature[]
): atlas.data.Feature<atlas.data.Geometry, null>[] {
  return lineFeatures.map((lineFeature) => {
    return {
      type: FeatureType.Feature,
      geometry: {
        type: GeometryType.LineString,
        coordinates: lineFeature.geometry.coordinates
      },
      properties: null
    } as atlas.data.Feature<atlas.data.Geometry, null>
  })
}

function mapPOIsToFeatures(
  POIs: POI[]
): atlas.data.Feature<atlas.data.Geometry, PointProperties>[] {
  return POIs.map((poi) => {
    let type = ''

    switch (poi.type) {
      case POIType.Structure:
        type = 'Kunstwerken'
        break
      case POIType.Location:
        type = 'Locaties'
        break
      default:
        break
    }

    return {
      type: FeatureType.Feature,
      geometry: {
        type: GeometryType.Point,
        coordinates: [parseFloat(poi.longitude), parseFloat(poi.latitude)]
      },
      properties: {
        name: poi.height ? `${poi.name} - hoogte: ${poi.height}cm` : poi.name,
        desc: poi.description,
        ele: poi.height || '',
        type: type
      }
    } as atlas.data.Feature<atlas.data.Geometry, PointProperties>
  })
}

function mapRoadWorksToFeatures(
  roadWorksArray: RoadWorks[]
): atlas.data.Feature<atlas.data.Geometry, PointProperties>[] {
  return roadWorksArray.map((roadWorks) => {
    return {
      type: FeatureType.Feature,
      geometry: {
        type: GeometryType.Point,
        coordinates: [parseFloat(roadWorks.longitude), parseFloat(roadWorks.latitude)]
      },
      properties: {
        name: roadWorks.title,
        desc: roadWorks.details,
        type: 'Wegenwerken',
        ele: ''
      }
    } as atlas.data.Feature<atlas.data.Geometry, PointProperties>
  })
}
