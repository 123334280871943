<script setup lang="ts">
import { type Ref, ref } from 'vue'
import { RiSearchLine } from '@remixicon/vue'
import InputText from 'primevue/inputtext'
import { showErrorToast } from '@/composables/toast'
import { useI18n } from 'vue-i18n'
import type { LocationType } from '@/enums/location-type'
import * as cfg from 'cfg'

const { t } = useI18n()

const emit = defineEmits<{
  search: [longitude: number, latitude: number, locationType: LocationType]
}>()

const location: Ref<string> = ref('')

async function search() {
  let url = `https://atlas.microsoft.com/search/fuzzy/json?api-version=1&countrySet=BE&query=${location.value}&subscription-key=${cfg.config.azureMapsSubscriptionKey}`

  const response = await fetch(url)
  const jsonData = await response.json()

  if (jsonData && jsonData.results && jsonData.results.length > 0) {
    const result = jsonData.results[0]

    if (result.position && result.position.lon && result.position.lat) {
      emit('search', result.position.lon, result.position.lat, result.type)
    }
  } else {
    showErrorToast(t('map.location-not-found'))
  }
}
</script>

<template>
  <div class="border-round-xl w-auto bg-white p-1">
    <div class="flex align-items-center justify-content-center">
      <InputText
        v-model="location"
        class="w-full mr-2"
        placeholder="Vul plaats of coördinaten (latitude,longitude) in"
        type="search"
        @keydown.enter="search"
      />
      <div title="Zoek locatie en visualiseer op de kaart">
        <RiSearchLine class="cursor-pointer mr-2" @click="search" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
