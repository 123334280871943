import { defineStore } from 'pinia'
import { apiClient } from '@/services/api.service'
import { ENDPOINTS } from '@/endpoints'
import { computed, ref, type Ref } from 'vue'
import type { RoadWorks } from '@/contracts/road-works'

export const useRoadWorksStore = defineStore('RoadWorks', () => {
  const _roadWorks: Ref<RoadWorks[]> = ref([])
  const roadWorks = computed(() => _roadWorks.value)

  const _roadWorksToIgnore: Ref<number[]> = ref([])
  const roadWorksToIgnore = computed(() => _roadWorksToIgnore)

  const _roadWorksVisible = ref(false)
  const roadWorksVisible = computed({
    get() {
      return _roadWorksVisible.value
    },
    set(isVisible: boolean) {
      _roadWorksVisible.value = isVisible
    }
  })

  async function fetchRoadworks() {
    const response = await apiClient.get(ENDPOINTS.ROAD_WORKS())
    _roadWorks.value = response.data
  }

  function setRoadWorksVisibility(isVisible: boolean) {
    _roadWorksVisible.value = isVisible
  }

  function ignoreRoadWorks(id: number) {
    _roadWorksToIgnore.value.push(id)
  }

  function dontIgnoreRoadWorks(id: number) {
    const index = _roadWorksToIgnore.value.indexOf(id)

    if (index !== -1) {
      _roadWorksToIgnore.value.splice(index, 1)
    }
  }

  function clearRoadWorksToIgnore() {
    _roadWorksToIgnore.value = []
  }

  return {
    roadWorks,
    roadWorksToIgnore,
    roadWorksVisible,
    fetchRoadworks,
    setRoadWorksVisibility,
    ignoreRoadWorks,
    dontIgnoreRoadWorks,
    clearRoadWorksToIgnore
  }
})
