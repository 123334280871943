<script setup lang="ts">
import FileUpload, { type FileUploadUploaderEvent } from 'primevue/fileupload'
import Dropdown from 'primevue/dropdown'
import { computed } from 'vue'
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import ProgressSpinner from 'primevue/progressspinner'
import type { Network } from '@/contracts/network'

const props = withDefaults(
  defineProps<{
    networks?: Network[]
    isUploadingFiles: boolean
  }>(),
  {
    networks: undefined
  }
)

const emit = defineEmits<{
  upload: [file: File, networkId?: number]
}>()

const maxFileSize = 25e6

const selectableNetworks = computed(() => {
  const networks = [{ id: -1, name: 'Alle netwerken' }]

  if (props.networks) {
    networks.unshift(...props.networks.map((network) => ({ id: network.id!, name: network.name })))
  }

  return networks
})

interface UploadForm {
  selectedNetwork: number | undefined
}

const schema: yup.AnyObject = yup.lazy(() => {
  return yup.object({
    selectedNetwork: yup.number().required()
  })
})

const { defineField } = useForm<UploadForm>({
  validationSchema: schema
})

const [selectedNetwork] = defineField('selectedNetwork')

async function uploadPOI(event: FileUploadUploaderEvent) {
  const file = event.files[0] as File

  if (selectedNetwork.value) {
    if (selectedNetwork.value === -1) {
      emit('upload', file)
    } else {
      emit('upload', file, selectedNetwork.value)
    }
  }
}
</script>

<template>
  <p class="font-semibold mt-5 mb-0">POI's updaten</p>
  <p class="mb-2">
    Om veranderingen aan POI's door te voeren, dient het .KML bestand hier opgeladen te worden.
  </p>
  <div class="flex flex-column" v-if="!isUploadingFiles">
    <Dropdown
      v-model="selectedNetwork"
      :options="selectableNetworks"
      option-label="name"
      option-value="id"
      placeholder="Toepassen op"
      class="w-full mb-2"
    />
    <FileUpload
      choose-label="Kies bestand"
      upload-label="Upload"
      :showCancelButton="false"
      accept=".kml"
      :previewWidth="0"
      :fileLimit="1"
      :maxFileSize="maxFileSize"
      :disabled="!selectedNetwork"
      customUpload
      @uploader="uploadPOI"
    >
      <template #empty>
        <p>Of sleep uw bestand hierheen</p>
      </template>
    </FileUpload>
  </div>
  <div v-else class="flex align-items-center justify-content-center">
    <ProgressSpinner />
  </div>
</template>

<style scoped lang="scss"></style>
