import axios, { type AxiosRequestHeaders } from 'axios'
import { getErrorMessage, showErrorToast } from '@/composables/toast'
import { getAccessToken } from '@/utils/auth'

export const apiClient = axios.create({})

apiClient.interceptors.request.use(async (config) => {
  const accessToken = await getAccessToken()
  if (accessToken) {
    config.headers = {
      ...(config.headers || {}),
      Authorization: `Bearer ${accessToken}`
    } as AxiosRequestHeaders
  }
  return config
})

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const errorMessage = getErrorMessage(error)
    showErrorToast(errorMessage)
    return Promise.reject(error)
  }
)
