<script lang="ts" setup>
import { useUserStore } from '@/stores/user'
import Button from 'primevue/button'
import { RiGroupLine, RiStackLine, RiTreasureMapLine } from '@remixicon/vue'
import { MenuItems, useMenuStore } from '@/stores/menu'
import { ref } from 'vue'
import Avatar from '@/components/common/Avatar.vue'
import Menu from 'primevue/menu'
import { Role } from '@/utils/roles'
import { showConfirmationDialog } from '@/composables/confirmation'
import cfg from 'cfg'
import { resetMap } from '@/services/map.service'

const userStore = useUserStore()
const menuStore = useMenuStore()

const profileMenuVisible = ref()
const items = ref([
  {
    label: 'Profiel',
    items: [
      {
        label: 'Uitloggen',
        command: () => {
          logOut()
        }
      }
    ]
  }
])

async function logOut() {
  await showConfirmationDialog('Wilt u uitloggen?', 'U kunt later opnieuw inloggen', 'Uitloggen')
  await userStore.logoutUser()
}

function toggleProfileMenu(event: MouseEvent) {
  profileMenuVisible.value.toggle(event)
}

function changeActiveMenuItem(menuItem: MenuItems) {
  if (menuStore.activeMenuItem == menuItem) {
    menuStore.clearActiveMenuItem()
  } else {
    menuStore.setActiveMenuItem(menuItem)
  }
}

function resetApp() {
  resetMap()
  menuStore.clearActiveMenuItem()
}
</script>

<template>
  <nav
    class="nav-collapsed collapse-animation min-h-screen hidden sm:flex sm:flex-column justify-content-between z-5 sidebar"
  >
    <div class="flex flex-column">
      <div class="flex flex-column">
        <div class="mx-auto menu-item">
          <Button class="w-full my-1 menu-button h-3rem w-3rem" link @click="resetApp()">
            <img src="../../assets/images/dino.png" height="32" width="32" class="mx-auto" />
          </Button>
        </div>
        <div class="mx-auto menu-item">
          <Button
            class="w-full my-1 menu-button h-3rem w-3rem"
            :class="{ 'active-menu-button': menuStore.activeMenuItem == MenuItems.Map }"
            link
            @click="changeActiveMenuItem(MenuItems.Map)"
          >
            <RiTreasureMapLine class="w-full menu-item-icon" />
          </Button>
        </div>
        <div v-if="userStore.hasRole(Role.Dispatcher)" class="mx-auto menu-item">
          <Button
            class="w-full my-1 menu-button h-3rem w-3rem"
            :class="{ 'active-menu-button': menuStore.activeMenuItem == MenuItems.AddRoute }"
            link
            @click="changeActiveMenuItem(MenuItems.AddRoute)"
          >
            <RiStackLine class="w-full menu-item-icon" />
          </Button>
        </div>
        <div v-if="userStore.hasRole(Role.Dispatcher)" class="mx-auto menu-item">
          <a
            :href="`https://portal.azure.com/#blade/AppInsightsExtension/WorkbookViewerBlade/ComponentId/%2Fsubscriptions%2F00633bcb-dbc9-4bf6-984b-1fd643a3cfc3%2Fresourcegroups%2Frg-trex-dwk-${cfg.config.env}-weu%2Fproviders%2Fmicrosoft.insights%2Fcomponents%2F${cfg.config.env.substring(0, 1)}trexweu-appi-dwk/ConfigurationId/%2Fsubscriptions%2F00633bcb-dbc9-4bf6-984b-1fd643a3cfc3%2Fresourcegroups%2Frg-trex-dwk-${cfg.config.env}-weu%2Fproviders%2Fmicrosoft.insights%2Fworkbooks%2F3f73471a-47fb-4a5d-89e8-79b5f489dcd5/WorkbookTemplateName/Auditing/NotebookParams/%7B%22TimeRange%22%3A%7B%22durationMs%22%3A2592000000%7D%2C%22EventType%22%3A%5B%22CalculateRouteEvent%22%5D%7D`"
            target="_blank"
            class="w-full my-1 menu-button h-3rem w-3rem"
            :class="{ 'active-menu-button': menuStore.activeMenuItem == MenuItems.Auditing }"
            style="color: var(--white-color)"
          >
            <RiGroupLine class="w-full menu-item-icon" />
          </a>
        </div>
      </div>
    </div>
    <div class="mx-auto">
      <div class="mx-auto menu-item mb-3">
        <div class="card flex justify-content-center">
          <Avatar :name="userStore.user?.name" :shadow="true" @click="toggleProfileMenu" />
          <Menu ref="profileMenuVisible" id="overlay_menu" :model="items" :popup="true" />
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.sidebar {
  width: var(--sidebar-collapsed-width);
  background-color: var(--dark-gray-color);
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.nav-link-icon {
  flex-shrink: 0;
}

.menu-button {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: color 0.1s linear;
  }
}

.menu-item-icon {
  width: 1.8rem;
  height: 1.8rem;
}

.menu-button.active-menu-button {
  background-color: var(--gray-color) !important;
  border-radius: 0.5rem;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.25);

  svg {
    color: var(--primary-color);
  }
}

.menu-item .menu-button:hover {
  background-color: var(--gray-color) !important;
  border-radius: 0.5rem;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.25);
}
</style>
