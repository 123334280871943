<script setup lang="ts">
import Dialog from 'primevue/dialog'
import InputNumber from 'primevue/inputnumber'
import InputSwitch from 'primevue/inputswitch'
import Dropdown from 'primevue/dropdown'
import { useNetworkStore } from '@/stores/network'
import { useDebugStore } from '@/stores/debug'
import Button from 'primevue/button'

const networkStore = useNetworkStore()
const debugStore = useDebugStore()

const isVisible = defineModel<boolean>()

document.addEventListener('keydown', function (event) {
  if (event.ctrlKey && event.altKey && event.key === 'd') {
    isVisible.value = true
  }
})

function saveParameters() {
  window.location.reload()
}
</script>

<template>
  <Dialog
    v-model:visible="isVisible"
    :draggable="false"
    modal
    header="Debugging parameters"
    :style="{ width: '600px' }"
  >
    <div class="grid">
      <p class="font-semibold mt-1 mb-0 col-12">Gegevens op kaart</p>

      <div class="col-12 flex align-items-center">
        <InputSwitch
          inputId="showNetworkFileMetadata"
          v-model="debugStore.showNetworkFileMetadata"
        />
        <label class="pl-2" for="showNetworkFileMetadata"
          >Toon netwerksegmenten en restricties op de kaart</label
        >
      </div>

      <div class="col-12 flex align-items-center">
        <InputSwitch inputId="showRouteSegments" v-model="debugStore.showRouteSegments" />
        <label class="pl-2" for="showRouteSegments"
          >Toon routesegmenten en coördinaten op de kaart</label
        >
      </div>

      <p class="font-semibold mt-3 mb-0 col-12">Track cöordinaten</p>
      <div class="col-12 flex align-items-center">
        <InputSwitch inputId="trackCoordinates" v-model="debugStore.trackCoordinates" />
        <label class="pl-2" for="trackCoordinates"
          >Toon coördinaten bij beweging van de muis op de kaart</label
        >
      </div>

      <p class="font-semibold mt-3 mb-0 col-12">Default voertuiggegevens</p>
      <div class="col-6">
        <label for="vehicleLength">Lengte (cm)</label>
        <InputNumber
          class="w-full"
          inputId="vehicleLength"
          :mode="'decimal'"
          :useGrouping="false"
          v-model="debugStore.vehicleLength"
        />
      </div>
      <div class="col-6">
        <label for="vehicleWidth">Breedte (cm)</label>
        <InputNumber
          class="w-full"
          inputId="vehicleWidth"
          :mode="'decimal'"
          :useGrouping="false"
          v-model="debugStore.vehicleWidth"
        />
      </div>
      <div class="col-6">
        <label for="vehicleHeight">Hoogte (cm)</label>
        <InputNumber
          class="w-full"
          inputId="vehicleHeight"
          :mode="'decimal'"
          :useGrouping="false"
          v-model="debugStore.vehicleHeight"
        />
      </div>
      <div class="col-6">
        <label for="vehicleWeight">Gewicht (kg)</label>
        <InputNumber
          class="w-full"
          inputId="vehicleWeight"
          :mode="'decimal'"
          :useGrouping="false"
          v-model="debugStore.vehicleWeight"
        />
      </div>

      <p class="font-semibold mt-3 mb-0 col-12">Default routecoördinaten</p>
      <div class="col-6">
        <label for="startLatitude">Latitude van startpunt</label>
        <InputNumber
          class="w-full"
          input-id="startLatitude"
          :minFractionDigits="0"
          :maxFractionDigits="6"
          locale="en-US"
          v-model="debugStore.startLatitude"
        />
      </div>
      <div class="col-6">
        <label for="startLongitude">Longitude van startpunt</label>
        <InputNumber
          class="w-full"
          input-id="startLongitude"
          :minFractionDigits="0"
          :maxFractionDigits="6"
          locale="en-US"
          v-model="debugStore.startLongitude"
        />
      </div>
      <div class="col-6">
        <label for="endLatitude">Latitude van eindpunt</label>
        <InputNumber
          class="w-full"
          input-id="endLatitude"
          :minFractionDigits="0"
          :maxFractionDigits="6"
          locale="en-US"
          v-model="debugStore.endLatitude"
        />
      </div>
      <div class="col-6">
        <label for="endLongitude">Longitude van eindpunt</label>
        <InputNumber
          class="w-full"
          input-id="endLongitude"
          :minFractionDigits="0"
          :maxFractionDigits="6"
          locale="en-US"
          v-model="debugStore.endLongitude"
        />
      </div>

      <p class="font-semibold mt-3 mb-0 col-12">Default netwerk</p>
      <Dropdown
        input-id="networkId"
        :options="networkStore.networks"
        option-label="name"
        option-value="id"
        class="col-6 p-0"
        :show-clear="true"
        v-model="debugStore.networkId"
      />
      <div class="flex justify-content-left flex-wrap gap-2 mt-3 mb-0 col-12">
        <Button
          class="shadow-container border-round-xl"
          type="button"
          label="Annuleren"
          severity="secondary"
          @click="() => (isVisible = false)"
        ></Button>
        <Button
          class="shadow-container border-round-xl"
          type="button"
          label="Opslaan"
          @click="saveParameters"
        ></Button>
      </div>
    </div>
  </Dialog>
</template>

<style scoped lang="scss">
.shadow-container {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
}
</style>
