<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import SidebarExpanded from '@/views/authenticatedViews/SidebarExpanded.vue'
import NewRouteView from '@/views/authenticatedViews/sidebarExpandedContent/NewRouteView.vue'
import UploadView from '@/views/authenticatedViews/sidebarExpandedContent/UploadView.vue'
import { MenuItems, useMenuStore } from '@/stores/menu'
import { useRouteStore } from '@/stores/route'
import LogoOverlay from '@/components/LogoOverlay.vue'
import LocationSearchInput from '@/components/LocationSearchInput.vue'
import NetworkSelector from '@/components/NetworkSelector.vue'
import {
  addNetworkToMap,
  initMap,
  removeNetworkFromMap,
  removeRouteFromMap,
  zoomAndCenterOnLocation
} from '@/services/map.service'
import { useMapCoordinateSelectionStore } from '@/stores/mapCoordinateSelection'
import { LocationType } from '@/enums/location-type'
import Button from 'primevue/button'
import CustomDialog from '@/components/common/CustomDialog.vue'
import ZoomControls from '@/components/common/ZoomControls.vue'
import POIVisibilityToggle from '@/components/common/POIVisibilityToggle.vue'
import { exportRouteToGpx } from '@/utils/gpx-utils'
import { showErrorToast } from '@/composables/toast'
import { usePOIStore } from '@/stores/POI'
import { useNetworkStore } from '@/stores/network'
import RoadWorksVisibilityToggle from '@/components/common/RoadWorksVisibilityToggle.vue'
import { useRoadWorksStore } from '@/stores/roadWorks'
import { RiDeleteBin2Line, RiEdit2Line, RiExportLine } from '@remixicon/vue'
import InputText from 'primevue/inputtext'
import InputGroup from 'primevue/inputgroup'
import InputGroupAddon from 'primevue/inputgroupaddon'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import SmallError from '@/components/SmallError.vue'
import FloatLabel from 'primevue/floatlabel'
import ProgressBar from 'primevue/progressbar'
import DebugDialog from '@/components/common/DebugDialog.vue'
import CoordinatesViewer from '@/components/common/CoordinatesViewer.vue'
import { useDebugStore } from '@/stores/debug'

const mapDiv = ref()
const exportDialogVisible = ref(false)
const exportClicked = ref(false)

const menuStore = useMenuStore()
const routeStore = useRouteStore()
const POIStore = usePOIStore()
const roadWorksStore = useRoadWorksStore()
const networkStore = useNetworkStore()
const debugStore = useDebugStore()
const mapCoordinateSelectionStore = useMapCoordinateSelectionStore()

onMounted(async () => {
  await initMap(mapDiv.value)
})

watch(
  () => routeStore.route,
  () => {
    if (routeStore.route) {
      POIStore.setPOIsVisibility(false)
      roadWorksStore.setRoadWorksVisibility(false)
    }
  }
)

interface ExportForm {
  exportGpxFileName: string
}

const schema: yup.AnyObject = yup.lazy(() => {
  return yup.object({
    exportGpxFileName: yup.string().required()
  })
})

const { defineField, errors, meta, validate } = useForm<ExportForm>({
  validationSchema: schema
})

const [exportGpxFileName] = defineField('exportGpxFileName')
exportGpxFileName.value = 'reisweg-export'

async function searchLocation(longitude: number, latitude: number, locationType: LocationType) {
  let zoom: number

  switch (locationType) {
    case LocationType.Geography:
      zoom = 13
      break
    case LocationType.Street:
      zoom = 15
      break
    case LocationType.POI:
      zoom = 17
      break
    case LocationType.PointAddress:
      zoom = 19
      break
    case LocationType.CrossStreet:
      zoom = 16
      break
    case LocationType.AddressRange:
      zoom = 15
      break
    default:
      zoom = 12
  }

  zoomAndCenterOnLocation(longitude, latitude, zoom)
}

function setNewRouteMenuActive() {
  menuStore.setActiveMenuItem(MenuItems.Map)
}

function toggleExportDialog() {
  exportDialogVisible.value = !exportDialogVisible.value
}

async function exportToGpx() {
  exportClicked.value = true

  const { valid } = await validate()

  if (valid) {
    try {
      await exportRouteToGpx(routeStore.route!, exportGpxFileName.value)
    } catch (error: any) {
      console.error(error)
      showErrorToast(error)
    } finally {
      toggleExportDialog()
    }
  }
}

function deleteRoute() {
  removeRouteFromMap()
  routeStore.clearRoute()
  POIStore.clearPOIsToIgnore()
  roadWorksStore.clearRoadWorksToIgnore()
}

async function selectNetwork(networkId: number) {
  networkStore.setRenderingNetworkId(networkId)
  await addNetworkToMap(networkId)
  networkStore.clearRenderingNetworkId()
}

function unselectNetwork(networkId: number) {
  removeNetworkFromMap(networkId)
}
</script>

<template>
  <link
    href="https://atlas.microsoft.com/sdk/javascript/mapcontrol/3/atlas.min.css"
    rel="stylesheet"
  />
  <div class="overflow-y-hidden">
    <ProgressBar
      v-show="routeStore.isGeneratingRoute"
      mode="indeterminate"
      style="height: 3px"
      class="absolute top-0 z-5 left-0 right-0"
    />
    <div
      ref="mapDiv"
      class="maplibregl-map atlas-map"
      :class="{ 'cursor-crosshair': mapCoordinateSelectionStore.isAllowedToSetCoordinates }"
      style="height: 100vh"
    ></div>
    <div class="absolute mx-auto top-0 right-0 left-0 mt-3 w-30rem">
      <LocationSearchInput @search="searchLocation" class="shadow-container" />
    </div>
    <div class="absolute top-0 right-0 mt-3 mr-3">
      <LogoOverlay class="shadow-container" />
    </div>

    <SidebarExpanded :visible="menuStore.activeMenuItem == MenuItems.AddRoute">
      <UploadView />
    </SidebarExpanded>
    <SidebarExpanded :visible="menuStore.activeMenuItem == MenuItems.Map">
      <NewRouteView />
    </SidebarExpanded>

    <div class="absolute mx-auto mb-3 mr-10 bottom-0 right-0 left-0 network-selector">
      <NetworkSelector
        class="shadow-container"
        @selectNetwork="selectNetwork"
        @unselectNetwork="unselectNetwork"
      />
    </div>

    <div class="absolute mr-3 mb-3 bottom-6 right-0">
      <ZoomControls class="shadow-container" />
    </div>

    <div class="absolute mr-3 mb-3 bottom-3 right-0">
      <POIVisibilityToggle class="shadow-container" />
    </div>

    <div class="absolute mr-3 mb-3 bottom-0 right-0">
      <RoadWorksVisibilityToggle class="shadow-container" />
    </div>

    <div
      class="absolute ml-8 mb-3 bottom-0 left-0"
      v-if="debugStore.isDebuggingEnabled && debugStore.trackCoordinates"
    >
      <CoordinatesViewer class="shadow-container" />
    </div>

    <div
      class="action-buttons absolute top-0 left ml-3 mt-4 z-0"
      v-if="routeStore.isRouteAvailable"
    >
      <div class="flex flex-column gap-3">
        <Button
          class="shadow-container border-round-xl"
          severity="secondary"
          type="button"
          rounded
          @click="deleteRoute"
        >
          <RiDeleteBin2Line class="pr-1" />
          <span class="p-button-label">Reisweg verwijderen</span>
        </Button>
        <Button
          class="shadow-container border-round-xl"
          severity="secondary"
          type="button"
          rounded
          @click="setNewRouteMenuActive"
        >
          <RiEdit2Line class="pr-1" />
          <span class="p-button-label">Reisweg aanpassen</span>
        </Button>
        <Button class="shadow-container border-round-xl" type="button" @click="toggleExportDialog">
          <RiExportLine class="pr-1" />
          <span class="p-button-label">Reisweg exporteren</span>
        </Button>
      </div>
    </div>

    <CustomDialog
      v-model="exportDialogVisible"
      :primary-action="exportToGpx"
      :secondary-action="toggleExportDialog"
      primaryLabel="Exporteren"
      secondary-label="Annuleren"
      headerText="Wilt u de reisweg exporteren?"
      message-text="U exporteert de reisweg in .gpx formaat."
    >
      <InputGroup>
        <FloatLabel>
          <InputText id="exportGpxFileName" v-model="exportGpxFileName" class="w-full" />
          <label for="exportGpxFileName">Bestandsnaam</label>
        </FloatLabel>
        <InputGroupAddon>.gpx</InputGroupAddon>
      </InputGroup>
      <SmallError v-if="errors.exportGpxFileName && (meta.dirty || exportClicked)" class="mt-1 mb-5"
        >{{ errors.exportGpxFileName }}
      </SmallError>
      <div v-else class="mb-5"></div>
    </CustomDialog>
    <DebugDialog></DebugDialog>
  </div>
</template>

<style lang="scss">
.shadow-container {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
}

.icon-button {
  width: 2.75rem;
  height: 2.75rem;
  background-color: var(--white-color);

  &:hover {
    background-color: var(--light-gray-color);
    border-color: var(--light-gray-color);
  }
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.action-buttons {
  left: var(--sidebar-collapsed-width);
}

.bottom-3 {
  bottom: 3rem;
}

.bottom-6 {
  bottom: 6rem;
}

.network-selector {
  width: 40vw;
}

.map-copyright {
  display: none !important;
}

.generating-route-message {
  right: 7rem;
  background: rgba(219, 234, 254, 0.9);
}
</style>
