import { createRouter, createWebHistory } from 'vue-router'
import { RouteName, RouteRootPath } from '@/router/routes'
import AuthenticatedRootView from '@/views/AuthenticatedRootView.vue'
import UnauthorizedView from '@/views/UnauthorizedView.vue'
import LoggedOutView from '@/views/LoggedOutView.vue'
import { authGuard } from '@auth0/auth0-vue'
import { useUserStore } from '@/stores/user'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: RouteRootPath.Root,
      name: RouteName.Authenticated,
      component: AuthenticatedRootView,
      beforeEnter: authGuard,
      redirect: {
        name: RouteName.Planner // Redirect to main view
      },
      children: [
        {
          path: RouteRootPath.Planner,
          name: RouteName.Planner,
          component: () => import('../views/authenticatedViews/PlannerView.vue')
        }
      ]
    },
    {
      path: RouteRootPath.Unauthorized,
      name: RouteName.Unauthorized,
      component: UnauthorizedView
    },
    {
      path: RouteRootPath.LoggedOut,
      name: RouteName.LoggedOut,
      component: LoggedOutView
    },
    {
      path: `/${RouteRootPath.AuthRoute}:pathMatch(.*)*`,
      name: RouteName.AuthRoute,
      beforeEnter: () => {
        // perform your logic here (e.g., check if authenticated)
        useUserStore().login()
      },
      redirect() {
        return { path: RouteRootPath.Root }
      }
    }
  ]
})

export default router
