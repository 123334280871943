<script setup lang="ts">
import { useDebugStore } from '@/stores/debug'

const debugStore = useDebugStore()
</script>

<template>
  <div
    class="bg-white border-round-xl px-3 py-2 flex flex-column justify-content-between"
    title="Coördinaten"
  >
    <div class="flex">
      <div class="w-3rem">Lat:</div>
      <div>{{ debugStore.latitude }}</div>
    </div>
    <div class="flex">
      <div class="w-3rem">Long:</div>
      <div>{{ debugStore.longitude }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
