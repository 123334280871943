import atlas from 'azure-maps-control'
import DataSource = atlas.source.DataSource
import Map = atlas.Map

export function buildAndAddDataSource(map: Map, enableClustering: boolean = true): DataSource {
  const datasource = new DataSource()

  if (enableClustering) {
    datasource.setOptions({ cluster: true })
  }

  map.sources.add(datasource)

  return datasource
}
