import { FILES_ENDPOINTS } from '@/endpoints/files'
import { NETWORKS_ENDPOINTS } from '@/endpoints/networks'
import { OSM_ENDPOINTS } from '@/endpoints/osm'
import { ROUTES_ENDPOINTS } from '@/endpoints/routes'
import { POI_ENDPOINTS } from '@/endpoints/POI'
import { ROAD_WORKS_ENDPOINTS } from '@/endpoints/road-works'

export const ENDPOINTS = {
  ...FILES_ENDPOINTS,
  ...NETWORKS_ENDPOINTS,
  ...OSM_ENDPOINTS,
  ...POI_ENDPOINTS,
  ...ROUTES_ENDPOINTS,
  ...ROAD_WORKS_ENDPOINTS
}
