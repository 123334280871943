<script setup lang="ts">
import { type Ref, watch } from 'vue'
import InputNumber from 'primevue/inputnumber'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import { useNetworkStore } from '@/stores/network'
import { RiCrosshair2Line } from '@remixicon/vue'
import { useMapCoordinateSelectionStore } from '@/stores/mapCoordinateSelection'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import SmallError from '@/components/SmallError.vue'
import { useMenuStore } from '@/stores/menu'
import { useRouteStore } from '@/stores/route'
import { showWarningToast } from '@/composables/toast'
import { addRouteToMap, removeRouteFromMap } from '@/services/map.service'
import { usePOIStore } from '@/stores/POI'
import { useRoadWorksStore } from '@/stores/roadWorks'
import { useDebugStore } from '@/stores/debug'

const networkStore = useNetworkStore()
const menuStore = useMenuStore()
const routeStore = useRouteStore()
const POIStore = usePOIStore()
const roadWorksStore = useRoadWorksStore()
const mapCoordinateSelectionStore = useMapCoordinateSelectionStore()
const debugStore = useDebugStore()

const schema: yup.AnyObject = yup.lazy(() => {
  return yup.object({
    vehicleLength: yup.number().nullable(),
    vehicleWidth: yup.number().nullable(),
    vehicleHeight: yup.number().nullable(),
    vehicleWeight: yup.number().nullable(),
    startLatitude: yup.number().required(),
    startLongitude: yup.number().required(),
    endLatitude: yup.number().required(),
    endLongitude: yup.number().required(),
    networkId: yup.number().required()
  })
})

const { defineField, errors, validate, meta } = useForm({
  validationSchema: schema
})

const [vehicleLength]: [Ref<number | undefined>, any] = defineField('vehicleLength')
const [vehicleWidth]: [Ref<number | undefined>, any] = defineField('vehicleWidth')
const [vehicleHeight]: [Ref<number | undefined>, any] = defineField('vehicleHeight')
const [vehicleWeight]: [Ref<number | undefined>, any] = defineField('vehicleWeight')
const [startLongitude]: [Ref<number | undefined>, any] = defineField('startLongitude')
const [startLatitude]: [Ref<number | undefined>, any] = defineField('startLatitude')
const [endLongitude]: [Ref<number | undefined>, any] = defineField('endLongitude')
const [endLatitude]: [Ref<number | undefined>, any] = defineField('endLatitude')
const [networkId]: [Ref<number | undefined>, any] = defineField('networkId')

if (debugStore.isDebuggingEnabled) {
  vehicleLength.value = debugStore.vehicleLength
  vehicleWidth.value = debugStore.vehicleWidth
  vehicleHeight.value = debugStore.vehicleHeight
  vehicleWeight.value = debugStore.vehicleWeight
  startLatitude.value = debugStore.startLatitude
  startLongitude.value = debugStore.startLongitude
  endLatitude.value = debugStore.endLatitude
  endLongitude.value = debugStore.endLongitude
  networkId.value = debugStore.networkId
}

watch(
  () => mapCoordinateSelectionStore.selectedVehicleHeight,
  (newValue) => {
    vehicleHeight.value = newValue
  }
)

watch(
  () => mapCoordinateSelectionStore.selectedStartLatitude,
  (newValue) => {
    startLatitude.value = newValue
  }
)

watch(
  () => mapCoordinateSelectionStore.selectedStartLongitude,
  (newValue) => {
    startLongitude.value = newValue
  }
)

watch(
  () => mapCoordinateSelectionStore.selectedEndLatitude,
  (newValue) => {
    endLatitude.value = newValue
  }
)

watch(
  () => mapCoordinateSelectionStore.selectedEndLongitude,
  (newValue) => {
    endLongitude.value = newValue
  }
)

async function generateRoute() {
  await validate()

  if (meta.value.valid) {
    try {
      await routeStore.calculateRoute(
        networkId.value!,
        startLongitude.value!,
        startLatitude.value!,
        endLongitude.value!,
        endLatitude.value!,
        vehicleLength.value!,
        vehicleWidth.value!,
        vehicleHeight.value!,
        vehicleWeight.value!,
        POIStore.POIsToIgnore.value,
        roadWorksStore.roadWorksToIgnore.value
      )
      menuStore.clearActiveMenuItem()

      if (routeStore.route) {
        removeRouteFromMap()
        addRouteToMap(routeStore.route!)
      }
    } catch (error: any) {
      showWarningToast(error.message)
    }
  }
}
</script>

<template>
  <div class="w-22rem relative">
    <span class="title text-2xl font-semibold pb-2">Nieuwe reisweg</span>
    <p class="font-semibold mt-5 mb-0">Voertuiggegevens</p>
    <p class="mb-2"><span>Lengte </span><span class="font-light">(cm)</span></p>
    <InputNumber
      v-model="vehicleLength"
      class="w-full"
      placeholder="Voer lengte in"
      rules="required"
      :mode="'decimal'"
      :useGrouping="false"
    />
    <p class="mb-2"><span>Breedte </span><span class="font-light">(cm)</span></p>
    <InputNumber
      v-model="vehicleWidth"
      class="w-full"
      placeholder="Voer breedte in"
      rules="required"
      :useGrouping="false"
    />
    <p class="mb-2"><span>Hoogte </span><span class="font-light">(cm)</span></p>
    <InputNumber
      v-model="vehicleHeight"
      class="w-full"
      placeholder="Voer hoogte in"
      rules="required"
      :useGrouping="false"
    />
    <p class="mb-2"><span>Gewicht </span><span class="font-light">(kg)</span></p>
    <InputNumber
      v-model="vehicleWeight"
      class="w-full"
      placeholder="Voer gewicht in"
      rules="required"
      :useGrouping="false"
    />
    <p class="font-semibold mt-5 mb-0">Route plannen</p>
    <p class="mb-2"><span>Startpunt </span></p>
    <div class="flex gap-3">
      <div>
        <InputNumber
          class="small-inputnumber max-w-9rem"
          :minFractionDigits="0"
          :maxFractionDigits="6"
          v-model="startLatitude"
          placeholder="Latitude"
          locale="en-US"
        />
        <SmallError class="mt-1"> {{ errors.startLatitude }}</SmallError>
      </div>
      <div>
        <InputNumber
          class="small-inputnumber max-w-9rem"
          :minFractionDigits="0"
          :maxFractionDigits="6"
          v-model="startLongitude"
          placeholder="Longitude"
          locale="en-US"
        />
        <SmallError class="mt-1"> {{ errors.startLongitude }}</SmallError>
      </div>
      <ri-crosshair2-line
        class="text-primary cursor-pointer mt-2 w-2rem"
        @click="mapCoordinateSelectionStore.allowSetStartCoordinates()"
      />
    </div>
    <p class="mb-2"><span>Eindpunt </span></p>
    <div class="flex gap-3">
      <div>
        <InputNumber
          class="small-inputnumber max-w-9rem"
          :minFractionDigits="0"
          :maxFractionDigits="6"
          v-model="endLatitude"
          placeholder="Latitude"
          locale="en-US"
        />
        <SmallError class="mt-1"> {{ errors.endLatitude }}</SmallError>
      </div>
      <div>
        <InputNumber
          class="small-inputnumber max-w-9rem"
          :minFractionDigits="0"
          :maxFractionDigits="6"
          v-model="endLongitude"
          placeholder="Longitude"
          locale="en-US"
        />
        <SmallError class="mt-1"> {{ errors.endLongitude }}</SmallError>
      </div>
      <ri-crosshair2-line
        class="text-primary cursor-pointer mt-2 w-2rem"
        @click="mapCoordinateSelectionStore.allowSetEndCoordinates()"
      />
    </div>
    <p class="font-semibold mt-5 mb-0">Netwerk kiezen</p>
    <p class="mb-2"><span>Netwerk</span></p>
    <Dropdown
      v-model="networkId"
      :options="networkStore.networks"
      option-label="name"
      option-value="id"
      placeholder="Selecteer netwerk"
      class="w-full"
    />
    <SmallError class="mt-1"> {{ errors.networkId }}</SmallError>
    <Button
      class="mt-5 w-full"
      type="button"
      label="Genereer reisweg"
      rounded
      @click="generateRoute"
      :loading="routeStore.isGeneratingRoute"
      :disabled="!meta.valid"
    ></Button>
  </div>
</template>

<style scoped lang="scss">
.title {
  color: #fbc735;
  border-bottom: 5px solid #fbc735;
}

:deep(.small-inputnumber input) {
  min-width: 3rem !important;
}
</style>
