<script lang="ts" setup>
import { initToasts } from '@/composables/toast'
import Toast from 'primevue/toast'
import {
  RiCloseCircleLine,
  RiInformationLine,
  RiErrorWarningLine,
  RiCheckboxCircleLine
} from '@remixicon/vue'
initToasts()
</script>

<template>
  <Toast>
    <template #message="slotProps">
      <div class="w-full flex flex-column">
        <div class="flex align-items-center font-bold mb-3 text-xl">
          <RiCloseCircleLine v-if="slotProps.message.severity == 'error'" class="w-1 mr-2" />
          <RiInformationLine v-else-if="slotProps.message.severity == 'info'" class="w-1 mr-2" />
          <RiErrorWarningLine v-else-if="slotProps.message.severity == 'warn'" class="w-1 mr-2" />
          <RiCheckboxCircleLine
            v-else-if="slotProps.message.severity == 'success'"
            class="w-1 mr-2"
          />
          <div class="white-space-normal">{{ slotProps.message.summary }}</div>
        </div>
        <div v-html="slotProps.message.detail"></div>
      </div>
    </template>
  </Toast>
</template>

<style lang="scss" scoped></style>
