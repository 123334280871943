import atlas from 'azure-maps-control'
import { useNetworkStore } from '@/stores/network'
import Map = atlas.Map

export const mapIconStart = 'start'
export const mapIconEnd = 'end'
export const mapIconStructure = 'structure'
export const mapIconLocation = 'location'
export const mapIconRoadWorks = 'roadWorks'

const userImages = 'userImages'

export async function buildAndAddCustomMapIcons(map: Map) {
  await buildAndAddGeneralPOIIcons(map)
  await buildAndAddGeneralRoadWorksIcons(map)

  await buildAndAddNetworkPOIIcons(map)

  await buildAndAddRouteIcons(map)
  await buildAndAddRoutePOIIcons(map)
  await buildAndAddRouteRoadWorksIcons(map)

  await buildRouteIconsForDebugging(map)
  await buildNetworkIconsForDebugging(map)
}

async function buildAndAddGeneralPOIIcons(map: atlas.Map) {
  if (!map.imageSprite[userImages][`${mapIconStructure}-black`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconStructure}-black`,
      'marker',
      '#000000',
      '#fff'
    )
  }
  if (!map.imageSprite[userImages][`${mapIconStructure}-darkgrey`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconStructure}-darkgrey`,
      'marker',
      '#666666',
      '#fff'
    )
  }

  if (!map.imageSprite[userImages][`${mapIconLocation}-black`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconLocation}-black`,
      'marker-square',
      '#000000',
      '#fff'
    )
  }
  if (!map.imageSprite[userImages][`${mapIconLocation}-darkgrey`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconLocation}-darkgrey`,
      'marker-square',
      '#666666',
      '#fff'
    )
  }
}

async function buildAndAddGeneralRoadWorksIcons(map: atlas.Map) {
  if (!map.imageSprite[userImages][`${mapIconRoadWorks}-black`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconRoadWorks}-black`,
      'triangle',
      '#000000',
      '#fff'
    )
  }
  if (!map.imageSprite[userImages][`${mapIconRoadWorks}-darkgrey`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconRoadWorks}-darkgrey`,
      'triangle',
      '#666666',
      '#fff'
    )
  }
}

async function buildAndAddNetworkPOIIcons(map: atlas.Map) {
  const networkStore = useNetworkStore()
  const promises: Promise<any>[] = []

  for (const [colorName, colorValues] of Object.entries(networkStore.networkColors)) {
    if (!map.imageSprite[userImages][`${mapIconStructure}-${colorName}`]) {
      promises.push(
        map!.imageSprite.createFromTemplate(
          `${mapIconStructure}-${colorName}`,
          'marker',
          colorValues.hexValue,
          '#fff'
        )
      )
    }
    if (!map.imageSprite[userImages][`${mapIconLocation}-${colorName}`]) {
      promises.push(
        map!.imageSprite.createFromTemplate(
          `${mapIconLocation}-${colorName}`,
          'marker-square',
          colorValues.hexValue,
          '#fff'
        )
      )
    }
  }

  await Promise.all(promises)
}

async function buildRouteIconsForDebugging(map: atlas.Map) {
  // Used for visualization of coordinates of route segments. For debugging purposes
  if (!map.imageSprite[userImages][`${mapIconStart}-darkgrey`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconStart}-darkgrey`,
      'pin-round',
      '#666666',
      '#00000099'
    )
  }
  if (!map.imageSprite[userImages][`${mapIconStart}-black`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconStart}-black`,
      'pin-round',
      '#000000',
      '#00000099'
    )
  }
}

async function buildNetworkIconsForDebugging(map: atlas.Map) {
  // Used for visualization of outer points of networks. For debugging purposes

  const networkStore = useNetworkStore()
  const promises: Promise<any>[] = []

  for (const [colorName, colorValues] of Object.entries(networkStore.networkColors)) {
    if (!map.imageSprite[userImages][`${mapIconStart}-${colorName}`]) {
      promises.push(
        map!.imageSprite.createFromTemplate(
          `${mapIconStart}-${colorName}`,
          'pin-round',
          colorValues.hexValue,
          '#00000099'
        )
      )
    }
  }

  await Promise.all(promises)
}

async function buildAndAddRouteIcons(map: atlas.Map) {
  if (!map.imageSprite[userImages][`${mapIconStart}-red`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconStart}-red`,
      'pin-round',
      '#ff0000',
      '#00000099'
    )
  }
  if (!map.imageSprite[userImages][`${mapIconStart}-darkred`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconStart}-darkred`,
      'pin-round',
      '#cc0303',
      '#00000099'
    )
  }

  if (!map.imageSprite[userImages][`${mapIconEnd}-red`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconEnd}-red`,
      'flag-triangle',
      '#ff0000',
      '#00000099'
    )
  }
  if (!map.imageSprite[userImages][`${mapIconEnd}-darkred`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconEnd}-darkred`,
      'flag-triangle',
      '#cc0303',
      '#00000099'
    )
  }
}

async function buildAndAddRoutePOIIcons(map: atlas.Map) {
  if (!map.imageSprite[userImages][`${mapIconStructure}-red`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconStructure}-red`,
      'marker',
      '#ff0000',
      '#fff'
    )
  }
  if (!map.imageSprite[userImages][`${mapIconStructure}-darkred`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconStructure}-darkred`,
      'marker',
      '#cc0303',
      '#fff'
    )
  }

  if (!map.imageSprite[userImages][`${mapIconLocation}-red`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconLocation}-red`,
      'marker-square',
      '#ff0000',
      '#fff'
    )
  }
  if (!map.imageSprite[userImages][`${mapIconLocation}-darkred`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconLocation}-darkred`,
      'marker-square',
      '#cc0303',
      '#fff'
    )
  }
}

async function buildAndAddRouteRoadWorksIcons(map: atlas.Map) {
  if (!map.imageSprite[userImages][`${mapIconRoadWorks}-red`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconRoadWorks}-red`,
      'triangle',
      '#ff0000',
      '#fff'
    )
  }
  if (!map.imageSprite[userImages][`${mapIconRoadWorks}-darkred`]) {
    await map!.imageSprite.createFromTemplate(
      `${mapIconRoadWorks}-darkred`,
      'triangle',
      '#cc0303',
      '#fff'
    )
  }
}
