import { defineStore } from 'pinia'
import { computed, type Ref, ref } from 'vue'
import { apiClient } from '@/services/api.service'
import { ENDPOINTS } from '@/endpoints'
import { addNetworkToMap, removeNetworkFromMap } from '@/services/map.service'
import type { GeoJsonNetworkData, Network, NetworkFile } from '@/contracts/network'
import { formatDate } from '@/utils/date-utils'

type NetworkColors = {
  [key: string]: ColorValues
}

export type ColorValues = {
  hexValue: string
  textColor: 'black' | 'white'
}

export const useNetworkStore = defineStore('network', () => {
  const _networks: Ref<Network[]> = ref([])
  const _selectedNetworks: Ref<number[]> = ref([])
  const _isLoadingNetworks = ref(false)
  const _renderingNetworkId: Ref<number | undefined> = ref()

  const networks = computed(() => _networks.value)
  const selectedNetworks = computed(() => _selectedNetworks.value)
  const isLoadingNetworks = computed(() => _isLoadingNetworks.value)

  const networkColors: NetworkColors = {
    blue: {
      hexValue: '#003FE0',
      textColor: 'white'
    },
    green: {
      hexValue: '#009706',
      textColor: 'white'
    },
    indigo: {
      hexValue: '#3D2582',
      textColor: 'white'
    },
    fuchsia: {
      hexValue: '#BF1A73',
      textColor: 'white'
    },
    turquoise: {
      hexValue: '#03A7BD',
      textColor: 'white'
    },
    taupe: {
      hexValue: '#5F5E5E',
      textColor: 'white'
    },
    bordeaux: {
      hexValue: '#630A0A',
      textColor: 'white'
    },
    violet: {
      hexValue: '#958CFD',
      textColor: 'white'
    },
    rust: {
      hexValue: '#AD5300',
      textColor: 'white'
    },
    darkgray: {
      hexValue: '#4c4c4c',
      textColor: 'white'
    },
    darkgreen: {
      hexValue: '#015c05',
      textColor: 'white'
    },
    darkblue: {
      hexValue: '#01247e',
      textColor: 'white'
    }
  }

  async function fetchDataOfNetwork(networkId: number) {
    const response = await apiClient.get<GeoJsonNetworkData>(ENDPOINTS.network(networkId!))
    const network = _networks.value!.find((network: Network) => network.id === networkId)

    if (network) {
      network.geoData = response.data
    }
  }

  async function fetchDataOfNetworks() {
    _networks.value!.map(async (network: Network) => {
      const response = await apiClient.get<GeoJsonNetworkData>(ENDPOINTS.network(network.id!))
      network.geoData = response.data

      return network
    })
  }

  async function fetchNetworks() {
    _isLoadingNetworks.value = true

    const response = await apiClient.get<Network[]>(ENDPOINTS.networks())
    _networks.value = response.data
    _networks.value.map((n) => {
      n.color = getNetworkColor(n.id)
      return n
    })

    _isLoadingNetworks.value = false

    await fetchDataOfNetworks()
  }

  async function deleteNetwork(networkId: number) {
    await apiClient.delete(ENDPOINTS.network(networkId))

    delete _networks.value[networkId]
    removeSelectedNetwork(networkId)

    const networks = _networks.value!.filter((network: Network) => network.id !== networkId)
    _networks.value = [...networks]
  }

  function addSelectedNetwork(networkId: number) {
    _selectedNetworks.value = [...selectedNetworks.value, networkId]
  }

  function removeSelectedNetwork(networkId: number) {
    _selectedNetworks.value = [...selectedNetworks.value.filter((id: number) => id !== networkId)]
  }

  async function createNetwork(file: File, networkName: any) {
    const formData = new FormData()
    formData.append('networkName', networkName)
    formData.append('file', file)

    const response = await apiClient.post<Network>(ENDPOINTS.networks(), formData)
    const network = response.data
    network.color = getNetworkColor(network.id)

    // Already add network to the list
    _networks.value = [..._networks.value, network]

    return network
  }

  async function uploadNetworkFile(networkId: number, file: File) {
    const formData = new FormData()
    formData.append('file', file)

    await apiClient.post(ENDPOINTS.networkFiles(networkId), formData)
  }

  async function fetchNetworkFiles(networkId: number) {
    const response = await apiClient.get<NetworkFile[]>(ENDPOINTS.networkFiles(networkId))

    _networks.value!.map((network: Network) => {
      if (network.id === networkId) {
        network.files = response.data
        network.files.map((f) => (f.uploadDate = formatDate(f.uploadDate)))
      }
      return network
    })
  }

  async function deleteNetworkFile(networkId: number, fileId: number) {
    await apiClient.delete(ENDPOINTS.networkFile(networkId, fileId))

    _networks.value!.map((network: Network) => {
      if (network.id === networkId) {
        network.files = network.files!.filter((file: NetworkFile) => file.id !== fileId)
      }

      return network
    })
  }

  function getNetworkColor(networkId: number): [string, ColorValues] {
    // const index = _networks.value!.findIndex((network: Network) => network.id === networkId)
    const allNetworkColors = Object.entries(networkColors)
    const usedNetworkColorNames = _networks.value.filter((n) => n.color).map((n) => n.color[0])
    const unusedNetworkColors = allNetworkColors.filter(
      (c) => !usedNetworkColorNames.includes(c[0])
    )

    if (unusedNetworkColors.length > 0) {
      return unusedNetworkColors[0]
    } else {
      // Start over with first color
      return allNetworkColors[0]
    }
  }

  async function networkUpdated(networkId: number) {
    await fetchDataOfNetwork(networkId)
    await fetchNetworkFiles(networkId)

    if (selectedNetworks.value.includes(networkId)) {
      removeNetworkFromMap(networkId)
      await addNetworkToMap(networkId)
    }
  }

  function setRenderingNetworkId(networkId: number) {
    _renderingNetworkId.value = networkId
  }

  function clearRenderingNetworkId() {
    _renderingNetworkId.value = undefined
  }

  function isRenderingANetwork() {
    return !!_renderingNetworkId.value
  }

  function isRenderingNetworkWithId(networkId: number) {
    return _renderingNetworkId.value === networkId
  }

  return {
    networks,
    networkColors,
    selectedNetworks,
    isLoadingNetworks,
    fetchNetworks,
    deleteNetwork,
    addSelectedNetwork,
    removeSelectedNetwork,
    createNetwork,
    uploadNetworkFile,
    fetchNetworkFiles,
    deleteNetworkFile,
    networkUpdated,
    setRenderingNetworkId,
    clearRenderingNetworkId,
    isRenderingANetwork,
    isRenderingNetworkWithId
  }
})
