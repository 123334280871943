<script setup lang="ts">
import Checkbox from 'primevue/checkbox'
import { RiBarricadeLine, RiMapPin2Line } from '@remixicon/vue'
import {usePOIStore} from '@/stores/POI'
import {watch} from 'vue'
import {
  getPOIsAndAddToMap,
  getRoadWorksAndAddToMap,
  removePOIsFromMap,
  removeRoadWorksFromMap
} from '@/services/map.service'
import {useNetworkStore} from '@/stores/network'
import { useRoadWorksStore } from '@/stores/roadWorks'

const roadWorksStore = useRoadWorksStore()

watch(
    () => roadWorksStore.roadWorksVisible,
    () => {
      if (roadWorksStore.roadWorksVisible) {
        getRoadWorksAndAddToMap()
      } else {
        removeRoadWorksFromMap()
      }
    }
)
</script>

<template>
  <div class="bg-white border-round-xl px-3 py-2 flex" title="Wegenwerken">
    <RiBarricadeLine class="mr-2"/>
    <Checkbox v-model="roadWorksStore.roadWorksVisible" :binary="true"
              :title="roadWorksStore.roadWorksVisible ? 'Verberg wegenwerken op de kaart': 'Toon wegenwerken op de kaart'"/>
  </div>
</template>

<style scoped lang="scss"></style>
