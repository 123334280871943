<script setup lang="ts">
import { useNetworkStore } from '@/stores/network'
import { usePOIStore } from '@/stores/POI'
import { getPOIsAndAddToMap, removePOIsFromMap } from '@/services/map.service'
import { RiCloseLine, RiLoader3Line } from '@remixicon/vue'
import Button from 'primevue/button'

const networkStore = useNetworkStore()
const POIStore = usePOIStore()

const emit = defineEmits<{
  selectNetwork: [networkId: number]
  unselectNetwork: [networkId: number]
}>()

async function selectNetwork(networkId: number) {
  emit('selectNetwork', networkId)

  networkStore.addSelectedNetwork(networkId)

  if (POIStore.POIsVisible) {
    await getPOIsAndAddToMap(networkId)
  }
}

function unselectNetwork(networkId: number) {
  emit('unselectNetwork', networkId)

  networkStore.removeSelectedNetwork(networkId)

  if (POIStore.POIsVisible) {
    removePOIsFromMap(networkId)
  }
}

function networkHasSegments(networkId: number) {
  let hasSegments = false

  if (networkStore.networks && networkStore.networks.length > 0) {
    const network = networkStore.networks.find((n) => n.id === networkId)

    if (
      network &&
      network.geoData &&
      network.geoData.features &&
      network.geoData.features.length > 0
    ) {
      hasSegments = true
    }
  }

  return hasSegments
}
</script>

<template>
  <div class="bg-white px-3 py-2 border-round-xl">
    <div v-if="!networkStore.isLoadingNetworks">
      <div class="flex align-items-center justify-content-start">
        <div class="mr-2">Netwerken</div>
        <div class="flex align-items-center justify-content-start flex-wrap">
          <div v-for="network in networkStore.networks" :key="network.id!" class="my-1">
            <div v-if="!networkStore.selectedNetworks?.includes(network.id!)" class="mx-1">
              <Button
                :disabled="networkStore.isRenderingANetwork() || !networkHasSegments(network.id!)"
                @click="selectNetwork(network.id!)"
                class="network-selection-button__unselected white-space-nowrap"
                :title="'Visualiseer netwerk ' + network.name + ' op de kaart'"
              >
                <div class="network-selection-button">{{ network.name }}</div>
              </Button>
            </div>
            <div
              v-else
              :style="{
                'background-color': network.color[1].hexValue,
                'border-color': `${network.color[1].textColor} !important`
              }"
              class="border-round inline-block flex align-items-center mx-1"
            >
              <div class="white-space-nowrap mx-2">
                <Button
                  disabled
                  :title="network.name"
                  class="network-selection-button__selected block border-none"
                  :loading="networkStore.isRenderingANetwork()"
                  :pt="{
                    label: {
                      class: 'label font-normal'
                    }
                  }"
                  :style="{
                    'background-color': `${network.color[1].hexValue} !important`,
                    color: `${network.color[1].textColor} !important`,
                    opacity: '1 !important'
                  }"
                >
                  <RiLoader3Line
                    v-if="networkStore.isRenderingNetworkWithId(network.id)"
                    class="w-2rem vertical-align-middle rotating"
                  />
                  <div class="inline-block vertical-align-middle network-selection-button">
                    {{ network.name }}
                  </div>
                </Button>
              </div>
              <Button
                :title="'Verberg netwerk ' + network.name + ' van de kaart'"
                :disabled="networkStore.isRenderingANetwork()"
                @click="unselectNetwork(network.id!)"
                class="network-selection-button__selected block border-left-2 border-top-none border-right-none border-bottom-none"
                :style="{
                  'background-color': `${network.color[1].hexValue} !important`,
                  color: `${network.color[1].textColor} !important`,
                  'border-color': `${network.color[1].textColor} !important`
                }"
              >
                <RiCloseLine class="w-9 vertical-align-bottom" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <span class="inline-block">Netwerken laden...</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.network-selection-button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.network-selection-button__unselected {
  background-color: #ffffff !important;
  border: none !important;
  color: #000000 !important;
  font-weight: 600;
  text-decoration: underline;
  padding: 0 !important;

  &.p-disabled {
    color: #999999 !important;
  }
}

.network-selection-button__selected {
  padding: 0 !important;
}
</style>
