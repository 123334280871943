import { mapFeatures } from '@/utils/features-mapper'
import type { Route } from '@/contracts/route'
import GeoJsonToGpx from '@dwayneparton/geojson-to-gpx'

export async function exportRouteToGpx(route: Route, fileName: string) {
  const geoJson = mapFeatures(route)
  const options = {}

  const gpx = GeoJsonToGpx(geoJson, options)
  if (!gpx) {
    return Promise.reject('Er is een fout opgetreden bij het exporteren naar GPX.')
  }

  // convert document to string or post process it
  const gpxString = new XMLSerializer().serializeToString(gpx)

  const link = document.createElement('a')
  link.download = `${fileName}.gpx`
  const blob = new Blob([gpxString], { type: 'text/xml' })
  link.href = window.URL.createObjectURL(blob)
  link.click()

  return Promise.resolve()
}
