import { defineStore } from 'pinia'
import { computed, type Ref, ref } from 'vue'

export enum MenuItems {
  Map = 'map',
  AddRoute = 'AddRoute',
  Auditing = 'auditing'
}

export const useMenuStore = defineStore('menu', () => {
  const _activeMenuItem: Ref<MenuItems | undefined> = ref()
  const activeMenuItem = computed(() => _activeMenuItem.value)

  function setActiveMenuItem(items: MenuItems) {
    _activeMenuItem.value = items
  }

  function clearActiveMenuItem() {
    _activeMenuItem.value = undefined
  }

  return { activeMenuItem, setActiveMenuItem, clearActiveMenuItem }
})
