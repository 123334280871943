import { defineStore } from 'pinia'
import { computed, ref, type Ref } from 'vue'

export const useMapCoordinateSelectionStore = defineStore('mapCoordinateSelection', () => {
  const _selectedVehicleHeight: Ref<number | undefined> = ref()
  const _selectedStartLatitude: Ref<number | undefined> = ref()
  const _selectedStartLongitude: Ref<number | undefined> = ref()
  const _selectedEndLatitude: Ref<number | undefined> = ref()
  const _selectedEndLongitude: Ref<number | undefined> = ref()
  const _allowSetStartCoordinates: Ref<boolean> = ref(false)
  const _allowSetEndCoordinates: Ref<boolean> = ref(false)

  const selectedVehicleHeight = computed<number | undefined>(() => _selectedVehicleHeight.value)
  const selectedStartLatitude = computed<number | undefined>(() => _selectedStartLatitude.value)
  const selectedStartLongitude = computed<number | undefined>(() => _selectedStartLongitude.value)
  const selectedEndLatitude = computed<number | undefined>(() => _selectedEndLatitude.value)
  const selectedEndLongitude = computed<number | undefined>(() => _selectedEndLongitude.value)
  const isAllowedToSetCoordinates = computed<boolean>(
    () => _allowSetStartCoordinates.value || _allowSetEndCoordinates.value
  )

  function setSelectedCoordinates(longitude: number, latitude: number) {
    if (_allowSetStartCoordinates.value) {
      _selectedStartLongitude.value = longitude
      _selectedStartLatitude.value = latitude
      _allowSetStartCoordinates.value = false
    }
    if (_allowSetEndCoordinates.value) {
      _selectedEndLongitude.value = longitude
      _selectedEndLatitude.value = latitude
      _allowSetEndCoordinates.value = false
    }
  }

  function allowSetStartCoordinates() {
    _allowSetEndCoordinates.value = false
    _allowSetStartCoordinates.value = true
  }

  function allowSetEndCoordinates() {
    _allowSetStartCoordinates.value = false
    _allowSetEndCoordinates.value = true
  }

  function prohibitSetCoordinates() {
    _allowSetEndCoordinates.value = false
    _allowSetStartCoordinates.value = false
  }

  return {
    setSelectedCoordinates,
    selectedVehicleHeight,
    selectedStartLatitude,
    selectedStartLongitude,
    selectedEndLatitude,
    selectedEndLongitude,
    allowSetStartCoordinates,
    allowSetEndCoordinates,
    prohibitSetCoordinates,
    isAllowedToSetCoordinates
  }
})
