import atlas from 'azure-maps-control'
import { buildAndAddDataSource } from '@/utils/map/map-datasource-builder'
import DataSource = atlas.source.DataSource
import Map = atlas.Map

// POI's
export let structuresDataSource: DataSource
export let locationsDataSource: DataSource
export let roadWorksDataSource: DataSource

// Route
export let routeDataSource: DataSource
export let entryExitRouteDataSOurce: DataSource
export let startPointDataSource: DataSource
export let endPointDataSource: DataSource

// Route POI's
export let routeStructuresDataSource: DataSource
export let routeLocationsDataSource: DataSource
export let routeRoadWorksDataSource: DataSource

// Used for visualization of coordinates of route segments. For debugging purposes
export let debugRoutePointsDataSource: DataSource
export let debugRouteOuterPointsDataSource: DataSource

export type NetworkDataSources = {
  networkDataSource: DataSource
  structuresDataSource: DataSource
  locationsDataSource: DataSource
  debugNetworkPointsDataSource: DataSource
}

export function buildAndAddNetworkDataSources(map: Map): NetworkDataSources {
  const networkDataSource = buildAndAddDataSource(map!, false)
  const structuresDataSource = buildAndAddDataSource(map!)
  const locationsDataSource = buildAndAddDataSource(map!)
  const debugNetworkPointsDataSource = buildAndAddDataSource(map!)

  return {
    networkDataSource: networkDataSource,
    structuresDataSource: structuresDataSource,
    locationsDataSource: locationsDataSource,
    debugNetworkPointsDataSource: debugNetworkPointsDataSource
  }
}

export function buildAndAddGenericMapDataSources(map: Map) {
  //POI's
  structuresDataSource = buildAndAddDataSource(map)
  locationsDataSource = buildAndAddDataSource(map)
  roadWorksDataSource = buildAndAddDataSource(map)

  // Route
  routeDataSource = buildAndAddDataSource(map, false)
  entryExitRouteDataSOurce = buildAndAddDataSource(map, false)
  startPointDataSource = buildAndAddDataSource(map, false)
  endPointDataSource = buildAndAddDataSource(map, false)

  // Route POI's
  routeStructuresDataSource = buildAndAddDataSource(map)
  routeLocationsDataSource = buildAndAddDataSource(map)

  // Route roadworks
  routeRoadWorksDataSource = buildAndAddDataSource(map)

  // Used for visualization of coordinates of route segments. For debugging purposes
  debugRoutePointsDataSource = buildAndAddDataSource(map)
  debugRouteOuterPointsDataSource = buildAndAddDataSource(map)
}

export function removeNetworkDataSources(map: Map, networkLayerAndDataSources: NetworkDataSources) {
  map!.sources.remove(networkLayerAndDataSources.networkDataSource)
  map!.sources.remove(networkLayerAndDataSources.structuresDataSource)
  map!.sources.remove(networkLayerAndDataSources.locationsDataSource)
  map!.sources.remove(networkLayerAndDataSources.debugNetworkPointsDataSource)
}
