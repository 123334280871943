export enum RouteName {
  Unauthorized = 'Unauthorized',
  Authenticated = 'Authenticated',
  LoggedOut = 'LoggedOut',
  Planner = 'Planner',
  AuthRoute = 'AuthRoute'
}

export enum RouteRootPath {
  Root = '/',
  Unauthorized = '/unauthorized',
  LoggedOut = '/loggedout',
  Planner = '/planner',
  AuthRoute = '/auth'
}
