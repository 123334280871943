<script lang="ts" setup>
import { RouteRootPath } from '@/router/routes'
import NavigationPanel from '@/components/common/NavigationPanel.vue'
import Button from 'primevue/button'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <div class="flex">
    <NavigationPanel class="col-fixed" />
    <main class="col flex flex-column justify-content-center align-items-center">
      <h2>{{ t('unauthorized.message') }}</h2>
      <RouterLink :to="RouteRootPath.Root" class="no-underline">
        <Button class="p-button-raised">{{ t('general.back-to-home') }}</Button>
      </RouterLink>
    </main>
  </div>
</template>
