// Network
import atlas, { Popup, PopupTemplate } from 'azure-maps-control'
import { createLineLayer, createSymbolLayer } from '@/utils/map/map-layer-factory'
import { usePOIStore } from '@/stores/POI'
import { POIType } from '@/enums/POI-type'
import { useRoadWorksStore } from '@/stores/roadWorks'
import DataSource = atlas.source.DataSource
import Map = atlas.Map

export const popup: Popup = new Popup()

export function buildAndAddSymbolLayer(
  map: Map,
  layerId: string,
  dataSource: DataSource,
  iconImage: string,
  color: string,
  getPopupTemplate: (ignore: boolean) => PopupTemplate,
  anchor:
    | 'center'
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right',
  beforeLayerId?: string
) {
  const { layer, clusterLayer } = createSymbolLayer(layerId, dataSource, iconImage, color, anchor)

  map!.layers.add([clusterLayer, layer], beforeLayerId)

  if (getPopupTemplate) {
    map!.events.add('click', layer, (e) => onFeatureClicked(e, map, getPopupTemplate))
  }
}

export function buildAndAddLineLayer(
  map: Map,
  layerId: string,
  dataSource: DataSource,
  isEntryOrExitRoute: boolean,
  strokeWidth: number,
  strokeColor: string,
  beforeLayerId?: string
) {
  const lineLayer = createLineLayer(layerId, dataSource, strokeColor, strokeWidth)

  if (isEntryOrExitRoute) {
    lineLayer.setOptions({ strokeDashArray: [0.1, 1.5] })
  }

  map!.layers.add(lineLayer, beforeLayerId)
}

export function removeLayer(map: Map, layerId: string) {
  const layer = map.layers.getLayerById(layerId)

  if (layer) {
    map.layers.remove(layerId)
  }
}

function onFeatureClicked(e, map: Map, getPopupTemplate: (ignore: boolean) => PopupTemplate) {
  const POIStore = usePOIStore()
  const roadWorksStore = useRoadWorksStore()

  //Make sure the event occurred on a shape feature.
  if (e.shapes && e.shapes.length > 0) {
    const properties = e.shapes[0].getProperties()
    const type = properties['type'] as POIType
    const id = properties['id'] as number

    let ignore: boolean
    if (type === POIType.RoadWorks) {
      ignore = !roadWorksStore.roadWorksToIgnore.value.includes(id)
    } else {
      ignore = !POIStore.POIsToIgnore.value.includes(id)
    }

    //Update the content and position of the popup.
    popup.setOptions({
      //Apply a template to the properties of the shape feature.
      content: atlas.PopupTemplate.applyTemplate(properties, getPopupTemplate(ignore)),
      position: e.shapes[0].getCoordinates(),
      pixelOffset: [0, -18]
    })

    popup.open(map)

    const keepRouteButton = document.getElementById('popup_keepRouteButton')
    const ignoreButton = document.getElementById('popup_ignorePointButton')
    const dontIgnoreButton = document.getElementById('popup_dontIgnorePointButton')

    if (keepRouteButton) {
      keepRouteButton.onclick = () => popup.close()
    }
    if (ignoreButton) {
      ignoreButton.onclick = () => {
        if (type === POIType.RoadWorks) {
          roadWorksStore.ignoreRoadWorks(id)
        } else {
          POIStore.ignorePOI(id)
        }

        popup.close()
      }
    }
    if (dontIgnoreButton) {
      dontIgnoreButton.onclick = () => {
        if (type === POIType.RoadWorks) {
          roadWorksStore.dontIgnoreRoadWorks(id)
        } else {
          POIStore.dontIgnorePOI(id)
        }

        popup.close()
      }
    }
  }
}
