<script lang="ts" setup>
import NavigationPanel from '@/components/common/NavigationPanel.vue'
import { setLocale } from 'yup'
import { useNetworkStore } from '@/stores/network'
import * as signalR from '@microsoft/signalr'
import { config } from 'cfg'
import { RealTimeDataTypes } from '@/enums/real-time-data-types'
import { showSuccessToast } from '@/composables/toast'
import type { NetworkUpdatedData } from '@/contracts/network'
import { onUnmounted } from 'vue'

setLocale({
  mixed: {
    required: 'Dit veld is verplicht.'
  },
  string: {
    max: ({ max }) => `De netwerknaam mag maximaal ${max} tekens bevatten.`
  }
})
const networkStore = useNetworkStore()

// setup SignalR connection
const connection = new signalR.HubConnectionBuilder()
  .withUrl(`${config.apiBaseUrl}/notificationHub`, { withCredentials: true })
  .build()

connection
  .start()
  .then(() => {
    console.log('SignalR Connected.')
  })
  .catch((err) => {
    console.error('SignalR Connection Error: ', err)
  })

connection.on(RealTimeDataTypes.NetworkUpdated, async (networkData: NetworkUpdatedData) => {
  console.log('Network updated: ', networkData.networkName)
  showSuccessToast(`Netwerk ${networkData.networkName} is geüpdatet`, 'Netwerk geüpdatet')
  await networkStore.networkUpdated(networkData.networkId)
})

onUnmounted(() => {
  connection.stop().then(() => {
    console.log('SignalR Disconnected.')
  })
})
</script>

<template>
  <NavigationPanel class="col-fixed h-screen fixed" />
  <main class="flex flex-column">
    <RouterView />
  </main>
</template>

<style lang="scss" scoped></style>
