import { defineStore } from 'pinia'
import { useAuth0 } from '@auth0/auth0-vue'
import { type Ref, ref } from 'vue'
import { parseJwt } from '@/utils/jwt-parser'
import { apiClient } from '@/services/api.service'

export const useUserStore = defineStore('user', () => {
  const { loginWithRedirect, logout, user, getAccessTokenSilently } = useAuth0()
  const permissions = ref([])
  const userRoles: Ref<Array<any> | undefined> = ref()

  async function logoutUser() {
    return await logout({ logoutParams: { returnTo: window.location.origin } })
  }

  async function login() {   
    // logout on every other device first by invalidating sessions
    return await loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.href
      }
    })
  }

  async function getUserRoles() {
    if (!userRoles.value) {
      const accessToken = await getAccessTokenSilently()
      userRoles.value = parseJwt(accessToken)['https://api.t-rexservices.be/roles']
    }
    return userRoles
  }

  function hasRole(role: string[] | string) {
    if (!userRoles.value) {
      console.error('User roles not loaded but trying to check for roles.')
      return false
    }
    if (typeof role === 'string') {
      if (userRoles.value?.includes(role)) return true
    } else {
      return role.some((r) => userRoles.value!.includes(r))
    }
  }

  return { login, logoutUser, user, permissions, getUserRoles, hasRole }
})
