<script setup lang="ts"></script>

<template>
  <img src="@/assets/images/logo.png" class="logo-overlay bg-white p-3 border-round-2xl" />
</template>

<style scoped lang="scss">
.logo-overlay {
  height: 80px;
}
</style>
