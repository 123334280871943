import { setLocale } from 'yup'
import { i18n } from '@/i18n'

const { t } = i18n.global

export function initValidation() {
  setLocale({
    string: {
      email: () => t('general.validation.email'),
      length: ({ length }) => t('general.validation.length', { length: length })
    },
    number: {
      moreThan: ({ more }) => t('general.validation.moreThan', { more: more })
    },
    mixed: {
      required: () => t('general.validation.required')
    }
  })
}
