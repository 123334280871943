import atlas, { type IconOptions } from 'azure-maps-control'
import { mapIconRoadWorks } from '@/utils/map/map-icon-builder'
import DataSource = atlas.source.DataSource
import SymbolLayer = atlas.layer.SymbolLayer

export function createSymbolLayer(
  layerId: string,
  dataSource: DataSource,
  iconImage: string,
  color: string,
  anchor?:
    | 'center'
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
): { layer: SymbolLayer; clusterLayer: SymbolLayer } {
  const iconOptions: IconOptions = {
    allowOverlap: true,
    size: 1
  }

  if (anchor) {
    iconOptions.anchor = anchor
  }

  const layer = new atlas.layer.SymbolLayer(dataSource, layerId, {
    iconOptions: { ...iconOptions, image: `${iconImage}-${color}` },
    textOptions: {
      color: color,
      textField: ['get', 'text'],
      font: ['StandardFont-Regular'],
      offset: [0, 2]
    },
    filter: ['!', ['has', 'point_count']]
  })

  const clusterLayer = new atlas.layer.SymbolLayer(dataSource, `${layerId}-clustered`, {
    iconOptions: {
      ...iconOptions,
      image: `${iconImage}-${color === 'black' ? 'darkgrey' : color === 'red' ? 'darkred' : color}`
    },
    textOptions: {
      color: 'white',
      textField: ['get', 'point_count_abbreviated'],
      font: ['StandardFont-Bold'],
      size: 14,
      offset: iconImage === mapIconRoadWorks ? [0, 0.8] : [0, 0]
    },
    filter: ['has', 'point_count']
  })

  return { layer, clusterLayer }
}

export function createLineLayer(
  layerId: string,
  dataSource: DataSource,
  color: string,
  strokeWidth: number = 2
) {
  return new atlas.layer.LineLayer(dataSource, layerId, {
    strokeColor: color,
    strokeWidth: strokeWidth,
    strokeOpacity: 0.9,
    type: 'line'
  })
}
