<script setup lang="ts">
import Avatar from 'primevue/avatar'
import { onBeforeMount, ref } from 'vue'

const props = defineProps({
  name: String,
  shadow: Boolean
})

const initial = ref('')

onBeforeMount(() => {
  initial.value = props.name!.charAt(0).toUpperCase()
})
</script>

<template>
  <Avatar
    class="cursor-pointer text-2xl"
    :class="shadow ? 'shadow' : ''"
    :label="initial"
    size="large"
    shape="circle"
    aria-haspopup="true"
    aria-controls="overlay_menu"
  />
</template>

<style scoped lang="scss">
.p-avatar {
  border: var(--primary-color) 0.2rem solid !important;
  background-color: var(--white-color) !important;
  color: var(--dark-gray-color) !important;
}

.shadow {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.75) !important;
}
</style>
