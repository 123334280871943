<script setup lang="ts">
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'

const isVisible = defineModel<boolean>()

defineProps<{
  primaryAction?: (event: MouseEvent) => void
  secondaryAction?: (event: MouseEvent) => void
  primaryLabel?: string
  secondaryLabel?: string
  headerText?: string
  messageText?: string
}>()
</script>

<template>
  <Dialog
    v-model:visible="isVisible"
    :draggable="false"
    modal
    :header="headerText"
    :style="{ width: '25rem' }"
  >
    <span class="p-text-secondary block mb-5">{{ messageText }}</span>
    <slot></slot>
    <div class="flex justify-content-around flex-wrap gap-2">
      <Button
        class="shadow-container border-round-xl"
        type="button"
        :label="secondaryLabel"
        severity="secondary"
        @click="secondaryAction"
      ></Button>
      <Button
        class="shadow-container border-round-xl"
        type="button"
        :label="primaryLabel"
        @click="primaryAction"
      ></Button>
    </div>
  </Dialog>
</template>

<style scoped lang="scss">
.shadow-container {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
}
</style>
