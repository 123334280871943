import { useToast } from 'primevue/usetoast'
import type { ToastServiceMethods } from 'primevue/toastservice'
import { i18n } from '@/i18n'
import { AxiosError } from 'axios'

let toast: ToastServiceMethods

const { t } = i18n.global

export function initToasts() {
  toast = useToast()
}

export function showSuccessToast(
  message: string,
  title: string = t('general.success'),
  life: number = 3000
) {
  toast.add({
    severity: 'success',
    summary: title,
    detail: message,
    life: life
  })
}

export function showInfoToast(
  message: string,
  title: string = t('general.info'),
  life: number = 3000
) {
  toast.add({
    severity: 'info',
    summary: title,
    detail: message,
    life: life
  })
}

export function showWarningToast(
  message: string,
  title: string = t('general.warning'),
  life: number = 5000
) {
  toast.add({
    severity: 'warn',
    summary: title,
    detail: message,
    life: life
  })
}

export function showErrorToast(
  message: string,
  title: string = t('general.error'),
  life: number = 7000
) {
  toast.add({
    severity: 'error',
    summary: title,
    detail: message,
    life: life
  })
}

export type ToastError = {
  response?: {
    status?: number
    data:
      | Array<{ status: number; errorMessage?: string }>
      | { status: number; errorMessage?: string }
  }
  code?: string
}

export function getErrorMessage(error: ToastError): string {
  if (error) {
    if (error.response && error.response.status) {
      if (error.response.status === 400 || error.response.status === 409) {
        if (error.response.data) {
          if (Array.isArray(error.response.data)) {
            return getListOfErrorMessages(error)
          }
          if (
            error.response.data.errorMessage !== null &&
            error.response.data.errorMessage !== undefined
          ) {
            return error.response.data.errorMessage
          }
        }
        return error.response.status === 409 ? t('error.conflict') : t('error.bad-request')
      }
    } else if (error.code && error.code === AxiosError.ERR_NETWORK) {
      return t('error.network')
    }
  }
  return t('error.generic')
}

function getListOfErrorMessages(error): string {
  const errorMessage: Array<string> = []

  if (error.response.data.length === 1) {
    errorMessage.push(error.response.data[0].errorMessage)
  } else if (error.response.data.length > 0) {
    errorMessage.push('<ul>')
    error.response.data.forEach((error) => {
      if (error.errorMessage) {
        errorMessage.push('<li>')
        errorMessage.push(error.errorMessage)
        errorMessage.push('</li>')
      }
    })
    errorMessage.push('</ul>')
  }

  return errorMessage.join('')
}
