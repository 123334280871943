import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
import ConfirmationService from 'primevue/confirmationservice'

import { registerServices } from './services/service.registrator'
import { serviceContainer } from './services/service.container'

import '@/assets/styles/main.scss'

import { loadLocaleMessages } from '@/plugins/i18n'

import VueSafeTeleport from 'vue-safe-teleport'
import { defaultLanguage, i18n } from '@/i18n'
import { initValidation } from '@/validation'
import Auth0Config from '@/utils/auth'
import { GeneralPrimeVuePassthrough } from '@/assets/styles/primevue-passtrough'

const app = createApp(App)
export const pinia = createPinia()

loadLocaleMessages(i18n, defaultLanguage).then(() => {
  app
    .use(ToastService)
    .use(pinia)
    .use(router)
    .use(PrimeVue, {
      unstyled: false,
      pt: GeneralPrimeVuePassthrough
    })
    .use(i18n)
    .use(ConfirmationService)
    .use(VueSafeTeleport)
    .use(Auth0Config)
    .directive('tooltip', Tooltip)

  initValidation()

  registerServices(serviceContainer, app)

  // Global error handler
  app.config.errorHandler = (err, instance, info) => {
    console.error('Global error:', err)
    console.info('Error info:', info)
  }

  app.mount('#app')
})
