<script setup lang="ts">
import { RiDeleteBinLine, RiPencilLine } from '@remixicon/vue'
import { computed, onMounted, ref } from 'vue'
import FileUpload, { type FileUploadUploaderEvent } from 'primevue/fileupload'
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import SmallError from '@/components/SmallError.vue'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import ProgressSpinner from 'primevue/progressspinner'
import IconField from 'primevue/iconfield'
import type { Network, NetworkFile } from '@/contracts/network'

const props = withDefaults(
  defineProps<{
    networks?: Network[]
    showNewNetworkDialog: boolean
    showUploadDialog: boolean
    isUploadingFiles: boolean
  }>(),
  {
    networks: undefined
  }
)

const emit = defineEmits<{
  createNetwork: [file: File, networkName: string]
  editNetwork: [networkId: number]
  deleteNetwork: [networkId: number]
  uploadFile: [networkId: number, file: File]
  deleteFile: [networkId: number, fileId: number]
  toggleNewNetworkDialog: [visible: boolean]
  toggleUploadDialog: [visible: boolean]
}>()

const maxFileSize = 25e6

const filters = ref()
const selectedNetworkId = ref<number>()
const selectedNetwork = computed(() => {
  if (props.networks && props.networks.length > 0 && selectedNetworkId.value) {
    return props.networks.find((network) => network.id === selectedNetworkId.value)
  } else {
    return undefined
  }
})

interface UploadForm {
  networkName: string
}

const schema: yup.AnyObject = yup.lazy(() => {
  return yup.object({
    networkName: yup
      .string()
      .required()
      .max(32)
      .matches(/^[a-zA-Z0-9 ]*$/, 'De netwerknaam mag alleen letters, cijfers en spaties bevatten.')
  })
})

const { defineField, errors, meta, resetForm } = useForm<UploadForm>({
  validationSchema: schema
})

const [networkName] = defineField('networkName')

const initFilters = () => {
  filters.value = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    fileName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
    },
    uploadDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
    }
  }
}

function createNetwork(event: FileUploadUploaderEvent) {
  emit('createNetwork', event.files[0] as File, networkName.value)
}

function editNetwork(networkId: number) {
  selectedNetworkId.value = networkId
  emit('editNetwork', networkId)
}

function uploadFile(event: FileUploadUploaderEvent) {
  emit('uploadFile', selectedNetwork.value!.id!, event.files[0] as File)
}

function toggleNewNetworkDialog(visible: boolean) {
  emit('toggleNewNetworkDialog', visible)
}

function toggleUploadDialog(visible: boolean) {
  emit('toggleUploadDialog', visible)
}

onMounted(() => {
  initFilters()
})
</script>

<template>
  <div class="block">
    <p class="font-semibold mt-5 mb-0">Netwerk updaten of toevoegen</p>
    <p class="mb-2">
      Om veranderingen aan netwerken door te voeren OF om nieuwe netwerken toe te voegen, dienen de
      .SHP en/of .GPX bestanden hier opgeladen te worden.
    </p>
    <!--    <p v-for="network in networkStore.availableNetworks">{{network.name}}</p>-->
    <DataTable :value="networks" class="border border-gray-200 border-1 border-solid border-round">
      <Column field="name" header="Naam" class="pr-1">
        <template #body="slotProps">
          <div class="network-name" :title="(slotProps.data as Network).name">
            {{ (slotProps.data as Network).name }}
          </div>
        </template>
      </Column>
      <Column header="" class="w-5rem pl-1">
        <template #body="slotProps">
          <div class="flex justify-content-end">
            <div
              :title="`Editeer netwerk ${(slotProps.data as Network).name}`"
              @click="() => editNetwork((slotProps.data as Network).id)"
              class="cursor-pointer mx-1"
            >
              <RiPencilLine />
            </div>
            <div
              :title="`Verwijder netwerk ${(slotProps.data as Network).name}`"
              @click="() => emit('deleteNetwork', (slotProps.data as Network).id)"
              class="cursor-pointer mx-1"
            >
              <RiDeleteBinLine />
            </div>
          </div>
        </template>
      </Column>
    </DataTable>
    <Button
      class="w-full mt-2"
      type="button"
      label="Netwerk toevoegen"
      rounded
      @click="() => toggleNewNetworkDialog(true)"
    ></Button>
  </div>

  <Dialog
    :draggable="false"
    :closable="!isUploadingFiles"
    :visible="showNewNetworkDialog"
    @update:visible="toggleNewNetworkDialog"
    modal
    header="Netwerk toevoegen"
    :style="{ width: '800px' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    @after-hide="resetForm"
  >
    <InputText
      v-if="!isUploadingFiles"
      v-model="networkName"
      class="w-full"
      placeholder="Voer netwerknaam in"
    />
    <SmallError v-if="errors.networkName && meta.dirty" class="mt-1"
      >{{ errors.networkName }}
    </SmallError>

    <div v-if="!isUploadingFiles" class="mt-4 font-semibold">Formaat bestandsnaam:</div>
    <div v-if="!isUploadingFiles" class="text-sm flex">
      <i>naam</i>
      <b>-</b>
      <i>lengte</i>
      <b>x</b>
      <i>breedte</i>
      <b>x</b>
      <i>hoogte</i>
      <b>-</b>
      <i>gewicht</i>
    </div>

    <div v-if="!isUploadingFiles" class="mt-3">
      <FileUpload
        choose-label="Kies bestand"
        upload-label="Upload"
        :showCancelButton="false"
        accept=".zip,.gpx"
        :previewWidth="0"
        :fileLimit="1"
        :maxFileSize="maxFileSize"
        customUpload
        @uploader="createNetwork"
        :disabled="!meta.valid"
      >
        <template #empty>
          <p>Of sleep uw bestand hierheen</p>
        </template>
      </FileUpload>
    </div>
    <div v-else class="flex align-items-center justify-content-center">
      <ProgressSpinner />
    </div>
  </Dialog>

  <Dialog
    :draggable="false"
    :visible="showUploadDialog"
    @update:visible="toggleUploadDialog"
    modal
    :header="'Bestanden uploaden voor ' + selectedNetwork?.name + ' netwerk'"
    :style="{ width: '50vw', height: '89vh' }"
    :pt="{
      content: {
        style: { height: '90vh' }
      }
    }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <div class="card flex justify-content-center">
      <DataTable
        v-model:filters="filters"
        size="small"
        :value="selectedNetwork?.files"
        class="w-full border border-gray-200 border-1 border-solid border-round"
        :globalFilterFields="['name', 'uploadDate']"
        sortField="name"
        scrollable
        scrollHeight="flex"
        style="height: 45vh"
        :sortOrder="1"
      >
        <template #header>
          <div class="flex justify-content-center flex-wrap mb-2">Geüploade bestanden</div>
          <div class="border-round-xl w-auto bg-white p-1">
            <IconField iconPosition="left">
              <InputText
                v-model="filters['global'].value"
                class="w-full mr-2"
                placeholder="Zoek op ..."
                type="search"
              />
            </IconField>
          </div>
        </template>
        <Column
          header="Naam"
          field="name"
          filterField="name"
          style="min-width: 12rem"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ (data as NetworkFile).name }}
          </template>
        </Column>
        <Column
          header="Toegevoegd"
          field="uploadDate"
          filterField="uploadDate"
          style="min-width: 12rem"
          :sortable="true"
        >
          <template #body="{ data }">
            <div class="flex justify-content-between flex-wrap">
              <div class="flex align-items-center justify-content-center">
                {{ (data as NetworkFile).uploadDate }}
              </div>
              <div class="flex align-items-center justify-content-center">
                <div
                  :title="`Verwijder file`"
                  @click="() => emit('deleteFile', selectedNetwork!.id, (data as NetworkFile).id)"
                  class="cursor-pointer mx-1"
                >
                  <RiDeleteBinLine />
                </div>
              </div>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>

    <div class="flex justify-content-center flex-wrap mb-2 font-bold mt-4">
      Nieuw bestand uploaden
    </div>

    <p>
      Upload bestanden met dezelfde naam om deze te overschrijven. Upload bestanden met een nieuwe
      titel om deze toe te voegen aan het netwerk.
    </p>

    <div v-if="!isUploadingFiles" class="mt-3">
      <FileUpload
        choose-label="Kies bestand"
        upload-label="Bestand uploaden"
        :showCancelButton="false"
        accept=".zip,.gpx"
        :previewWidth="0"
        :fileLimit="1"
        :maxFileSize="maxFileSize"
        customUpload
        @uploader="uploadFile"
        :pt="{ content: { class: 'p-0' } }"
      >
        <template #empty>
          <p class="px-4">Of sleep uw bestand hierheen</p>
        </template>
      </FileUpload>
    </div>
    <div v-else class="flex align-items-center justify-content-center">
      <ProgressSpinner />
    </div>
  </Dialog>
</template>

<style scoped lang="scss">
.network-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(var(--sidebar-expanded-width) - 16rem);
}
</style>
