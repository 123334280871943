import * as geometric from 'geometric'

export function getIndexOfLineStringIntersectingWithPoint(
  point: [number, number],
  featureCoordinates: [number, number][]
) {
  let indexOfLineStringIntersectingWithPoint = -1

  for (let i = 0; i < featureCoordinates.length; i++) {
    const start = featureCoordinates.at(i)
    const end = featureCoordinates.at(i + 1)

    if (!start || !end) {
      continue
    }

    const isPointOnLine = geometric.pointOnLine(point, [start, end], 1e-12)

    if (isPointOnLine) {
      indexOfLineStringIntersectingWithPoint = i
    }
  }

  return indexOfLineStringIntersectingWithPoint
}
