<script setup lang="ts">
import Checkbox from 'primevue/checkbox'
import {RiMapPin2Line} from '@remixicon/vue'
import {usePOIStore} from '@/stores/POI'
import {watch} from 'vue'
import {getPOIsAndAddToMap, removePOIsFromMap} from '@/services/map.service'
import {useNetworkStore} from '@/stores/network'

const POIStore = usePOIStore()
const networkStore = useNetworkStore()

watch(
    () => POIStore.POIsVisible,
    () => {
      if (POIStore.POIsVisible) {
        getPOIsAndAddToMap()

        if (networkStore.selectedNetworks.length > 0) {
          networkStore.selectedNetworks.forEach((networkId) => {
            getPOIsAndAddToMap(networkId)
          })
        }
      } else {
        removePOIsFromMap()

        if (networkStore.selectedNetworks.length > 0) {
          networkStore.selectedNetworks.forEach((networkId) => {
            removePOIsFromMap(networkId)
          })
        }
      }
    }
)
</script>

<template>
  <div class="bg-white border-round-xl px-3 py-2 flex" title="POI's">
    <RiMapPin2Line class="mr-2"/>
    <Checkbox v-model="POIStore.POIsVisible" :binary="true"
              :title="POIStore.POIsVisible ? 'Verberg POI\'s op de kaart': 'Toon POI\'s op de kaart'"/>
  </div>
</template>

<style scoped lang="scss"></style>
