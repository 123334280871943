export enum FeatureCollectionType {
    FeatureCollection = 'FeatureCollection'
}

export enum FeatureType {
    Feature = 'Feature'
}
export enum GeometryType {
    LineString = 'LineString',
    Point = 'Point',
}