import type { POI } from '@/contracts/POI'
import { POIType } from '@/enums/POI-type'

export function filterStructures(POIs?: POI[]) {
  return POIs?.filter((poi) => poi.type === POIType.Structure) || []
}

export function filterLocations(POIs?: POI[]) {
  return POIs?.filter((poi) => poi.type === POIType.Location) || []
}

export function filterRoadWorks(POIs?: POI[]) {
  return POIs?.filter((poi) => poi.type === POIType.RoadWorks) || []
}