<script lang="ts" setup>
import { RouterView } from 'vue-router'
import { initToasts } from '@/composables/toast'
import { initConfirmations } from '@/composables/confirmation'
import Toast from '@/components/common/Toast.vue'
import ConfirmDialog from 'primevue/confirmdialog'
import { useUserStore } from '@/stores/user'
import { useMapCoordinateSelectionStore } from '@/stores/mapCoordinateSelection'

//to trigger service injection so routing AuthGuard does not fail on f5 on secured page
const userStore = useUserStore()
const mapCoordinateSelectionStore = useMapCoordinateSelectionStore()
userStore.getUserRoles()

initToasts()
initConfirmations()

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') {
    mapCoordinateSelectionStore.prohibitSetCoordinates()
  }
})
</script>

<template>
  <Toast />
  <ConfirmDialog group="confirmation" />
  <RouterView />
</template>

<style lang="scss" scoped></style>
