// Network
import atlas, { PopupTemplate } from 'azure-maps-control'
import {
  mapIconEnd,
  mapIconLocation,
  mapIconRoadWorks,
  mapIconStart,
  mapIconStructure
} from '@/utils/map/map-icon-builder'
import type { ColorValues } from '@/stores/network'
import {
  buildAndAddLineLayer,
  buildAndAddSymbolLayer,
  removeLayer
} from '@/utils/map/map-layer-builder'
import {
  debugRouteOuterPointsDataSource,
  roadWorksDataSource
} from '@/utils/map/trex-map-datasource-builder'
import { useRouteStore } from '@/stores/route'
import { useDebugStore } from '@/stores/debug'
import DataSource = atlas.source.DataSource
import Map = atlas.Map

// Network
export const segmentsLayerId: string = 'segmentsLayer'

// POI's
export const structuresLayerId: string = 'structuresLayer'
export const locationsLayerId: string = 'locationsLayer'
export const roadWorksLayerId: string = 'roadWorksLayer'

// Route
export const startPointLayerId: string = 'startPointLayer'
export const endPointLayerId: string = 'endPointLayer'
export const entryExitRouteLayerId: string = 'entryExitRouteLayer'
export const routeLayerId: string = 'routeLayer'
export const debugRoutePointsLayerId: string = 'routePointsLayer'
export const debugRouteOuterPointsLayerId: string = 'routeOuterPointsLayer'
export const debugNetworkOuterPointsLayerId: string = 'networkOuterPointsLayer'

// POI's on route
export const routeStructuresLayerId: string = 'routeStructuresLayer'
export const routeLocationsLayerId: string = 'routeLocationsLayer'
export const routeRoadWorksLayerId: string = 'routeRoadWorksLayer'

export const getPopupButtons = (ignore: boolean = true) => {
  const keepRouteButton =
    '<button id="popup_keepRouteButton" class="p-button p-component p-button-secondary shadow-container border-round-xl py-1 mr-2">' +
    '       <span class="p-button-label">Behoud reisweg</span>' +
    '   </button>'

  const ignorePointButton =
    '<button id="popup_ignorePointButton" class="p-button p-component shadow-container border-round-xl py-1 ml-2">' +
    '       <span class="p-button-label">Vermijden</span>' +
    '   </button>'

  const dontIgnorePointButton =
    '<button id="popup_dontIgnorePointButton" class="p-button p-component shadow-container border-round-xl py-1 ml-2">' +
    '       <span class="p-button-label">Niet vermijden</span>' +
    '   </button>'

  let content = '<div class="flex pt-4">'
  content += keepRouteButton

  if (ignore) {
    content += ignorePointButton
  } else {
    content += dontIgnorePointButton
  }

  content += '</div'

  return content
}

const getStructurePopupTemplate: (ignore: boolean) => PopupTemplate = (ignore: boolean) => {
  const routeStore = useRouteStore()

  return {
    content: `<div style="font-family: 'Montserrat', sans-serif!important; width:100%;">
      Op deze plek bevindt zich een kunstwerk
      
      {desc}
      
      Hoogte {height}cm
      ${routeStore.isRouteAvailable ? getPopupButtons(ignore) : ''}
    </div>`,
    numberFormat: {
      maximumFractionDigits: 0
    },
    sandboxContent: false // allow use of css classes + allow calling methods
  } as PopupTemplate
}

const getLocationPopupTemplate: (ignore: boolean) => PopupTemplate = (ignore: boolean) => {
  const routeStore = useRouteStore()

  return {
    content: `<div style="font-family: 'Montserrat', sans-serif!important; width:100%;">
      Op deze plek bevindt zich een aangeduide locatie.
      
      {desc}
      ${routeStore.isRouteAvailable ? getPopupButtons(ignore) : ''}
    </div>`,
    sandboxContent: false // allow use of css classes + allow calling methods
  } as PopupTemplate
}

const getRoadWorksPopupTemplate: (ignore: boolean) => PopupTemplate = (ignore: boolean) => {
  const routeStore = useRouteStore()

  return {
    content: `<div style="font-family: 'Montserrat', sans-serif!important; width:100%;">
      {desc}
      ${routeStore.isRouteAvailable ? getPopupButtons(ignore) : ''}
    </div>`,
    numberFormat: {
      maximumFractionDigits: 0
    },
    sandboxContent: false // allow use of css classes + allow calling methods
  } as PopupTemplate
}

const getStartPointPopupTemplate: () => PopupTemplate = () => {
  return {
    content: `<div style="font-family: 'Montserrat', sans-serif!important; width:100%;">
      {desc}
    </div>`,
    sandboxContent: false // allow use of css classes + allow calling methods
  } as PopupTemplate
}

const getEndPointPopupTemplate: () => PopupTemplate = () => {
  return {
    content: `<div style="font-family: 'Montserrat', sans-serif!important; width:100%;">
      {desc}
    </div>`,
    sandboxContent: false // allow use of css classes + allow calling methods
  } as PopupTemplate
}

const getDebugRoutePointPopupTemplate: () => PopupTemplate = () => {
  return {
    content: `<div style="font-family: 'Montserrat', sans-serif!important; width:100%;">
      Punt van route segment.
      
      {desc}
    </div>`,
    sandboxContent: false // allow use of css classes + allow calling methods
  } as PopupTemplate
}

const getDebugRouteOuterPointPopupTemplate: () => PopupTemplate = () => {
  return {
    content: `<div style="font-family: 'Montserrat', sans-serif!important; width:100%;">
      Buitenste punt van route segment.
      
      {desc}
    </div>`
  } as PopupTemplate
}

const getNetworkPointPopupTemplate: () => PopupTemplate = () => {
  return {
    content: `<div style="font-family: 'Montserrat', sans-serif!important; width:100%;">
      Restricties:  
      {desc}
    </div>`
  } as PopupTemplate
}

export function buildAndAddGenericLayers(
  map: Map,
  structuresDataSource: DataSource,
  locationsDataSource: DataSource,
  routeDataSource: DataSource,
  entryExitRouteDataSource: DataSource,
  startPointDataSource: DataSource,
  endPointDataSource: DataSource,
  routeStructuresDataSource: DataSource,
  routeLocationsDataSource: DataSource,
  routeRoadWorksDataSource: DataSource,
  debugRoutePointsDataSource: DataSource
) {
  // Roadworks
  buildAndAddSymbolLayer(
    map!,
    roadWorksLayerId,
    roadWorksDataSource,
    mapIconRoadWorks,
    'black',
    getRoadWorksPopupTemplate,
    'center',
    routeLayerId
  )

  // POI's
  buildAndAddSymbolLayer(
    map!,
    structuresLayerId,
    structuresDataSource,
    mapIconStructure,
    'black',
    getStructurePopupTemplate,
    'center',
    roadWorksLayerId
  )
  buildAndAddSymbolLayer(
    map!,
    locationsLayerId,
    locationsDataSource,
    mapIconLocation,
    'black',
    getLocationPopupTemplate,
    'center',
    structuresLayerId
  )

  // Route roadworks
  buildAndAddSymbolLayer(
    map!,
    routeRoadWorksLayerId,
    routeRoadWorksDataSource,
    mapIconRoadWorks,
    'red',
    getRoadWorksPopupTemplate,
    'center'
  )

  // Route POI's
  buildAndAddSymbolLayer(
    map!,
    routeStructuresLayerId,
    routeStructuresDataSource,
    mapIconStructure,
    'red',
    getStructurePopupTemplate,
    'center',
    routeRoadWorksLayerId
  )
  buildAndAddSymbolLayer(
    map!,
    routeLocationsLayerId,
    routeLocationsDataSource,
    mapIconLocation,
    'red',
    getLocationPopupTemplate,
    'center',
    routeStructuresLayerId
  )

  // Route
  buildAndAddLineLayer(map!, routeLayerId, routeDataSource, false, 4, 'red', routeLocationsLayerId)
  buildAndAddLineLayer(
    map!,
    entryExitRouteLayerId,
    entryExitRouteDataSource,
    true,
    4,
    'red',
    routeLayerId
  )
  buildAndAddSymbolLayer(
    map!,
    startPointLayerId,
    startPointDataSource,
    mapIconStart,
    'red',
    getStartPointPopupTemplate,
    'center',
    entryExitRouteLayerId
  )
  buildAndAddSymbolLayer(
    map!,
    endPointLayerId,
    endPointDataSource,
    mapIconEnd,
    'red',
    getEndPointPopupTemplate,
    'bottom-left',
    entryExitRouteLayerId
  )

  // Used for visualization of coordinates of route segments. For debugging purposes
  buildAndAddSymbolLayer(
    map!,
    debugRoutePointsLayerId,
    debugRoutePointsDataSource,
    mapIconStart,
    'darkgrey',
    getDebugRoutePointPopupTemplate,
    'center'
  )
  buildAndAddSymbolLayer(
    map!,
    debugRouteOuterPointsLayerId,
    debugRouteOuterPointsDataSource,
    mapIconStart,
    'black',
    getDebugRouteOuterPointPopupTemplate,
    'center'
  )
}

export function buildAndAddNetworkLayers(
  map: Map,
  networkId: number,
  colors: [string, ColorValues],
  networkDataSource: DataSource,
  structuresDataSource: DataSource,
  locationsDataSource: DataSource,
  debugNetworkPointsDataSource: DataSource
) {
  const debugStore = useDebugStore()

  buildAndAddSymbolLayer(
    map,
    `${structuresLayerId}-${networkId}`,
    structuresDataSource,
    mapIconStructure,
    colors[0],
    getStructurePopupTemplate,
    'center'
  )

  buildAndAddSymbolLayer(
    map,
    `${locationsLayerId}-${networkId}`,
    locationsDataSource,
    mapIconLocation,
    colors[0],
    getLocationPopupTemplate,
    'center'
  )

  buildAndAddLineLayer(
    map,
    `${segmentsLayerId}-${networkId}`,
    networkDataSource,
    false,
    2,
    colors[1].hexValue,
    locationsLayerId
  )

  if (debugStore.isDebuggingEnabled && debugStore.showNetworkFileMetadata) {
    buildAndAddSymbolLayer(
      map,
      `${debugNetworkOuterPointsLayerId}-${networkId}`,
      debugNetworkPointsDataSource,
      mapIconStart,
      colors[0],
      getNetworkPointPopupTemplate,
      'center'
    )
  }
}

export function removeNetworkLayers(map: Map, networkId: number) {
  removeLayer(map, `${structuresLayerId}-${networkId}`)
  removeLayer(map, `${locationsLayerId}-${networkId}`)
  removeLayer(map, `${segmentsLayerId}-${networkId}`)
}
