import atlas, { AuthenticationType, type MapMouseEvent } from 'azure-maps-control'
import { useMapCoordinateSelectionStore } from '@/stores/mapCoordinateSelection'
import { useNetworkStore } from '@/stores/network'
import type { POI } from '@/contracts/POI'
import * as cfg from 'cfg'
import { usePOIStore } from '@/stores/POI'
import type { Network } from '@/contracts/network'
import type { GeoJsonLineFeature, GeoJsonPointFeature, Route } from '@/contracts/route'
import {
  buildAndAddGenericMapDataSources,
  buildAndAddNetworkDataSources,
  debugRouteOuterPointsDataSource,
  debugRoutePointsDataSource,
  endPointDataSource,
  entryExitRouteDataSOurce,
  locationsDataSource,
  type NetworkDataSources,
  removeNetworkDataSources,
  roadWorksDataSource,
  routeDataSource,
  routeLocationsDataSource,
  routeRoadWorksDataSource,
  routeStructuresDataSource,
  startPointDataSource,
  structuresDataSource
} from '@/utils/map/trex-map-datasource-builder'
import { buildAndAddCustomMapIcons } from '@/utils/map/map-icon-builder'
import {
  buildAndAddGenericLayers,
  buildAndAddNetworkLayers,
  locationsLayerId,
  removeNetworkLayers,
  roadWorksLayerId,
  routeLayerId,
  segmentsLayerId,
  structuresLayerId
} from '@/utils/map/trex-map-layer-builder'
import { popup } from '@/utils/map/map-layer-builder'
import { filterLocations, filterStructures } from '@/utils/POI-utils'
import { useRoadWorksStore } from '@/stores/roadWorks'
import type { RoadWorks } from '@/contracts/road-works'
import { formatDate } from '@/utils/date-utils'
import { getIndexOfLineStringIntersectingWithPoint } from '@/utils/map/point-calculation-utils'
import { POIType } from '@/enums/POI-type'
import Konami from 'konami'
import { useDebugStore } from '@/stores/debug'
import Feature = atlas.data.Feature

export const defaultZoom: number = 7.5
export const defaultCenterLongitude: number = 4.469936
export const defaultCenterLatitude: number = 50.503887

export let map: atlas.Map | undefined

type PointType = 'entry' | 'exit'

// Networks
const networkDataSources: { [networkId: string]: NetworkDataSources } = {}

const delay = async (ms = 1000) => new Promise((resolve) => setTimeout(resolve, ms))

export async function initMap(mapDiv: HTMLDivElement) {
  const mapCoordinateSelectionStore = useMapCoordinateSelectionStore()
  const debugStore = useDebugStore()

  map = new atlas.Map(mapDiv, {
    center: [defaultCenterLongitude, defaultCenterLatitude],
    zoom: defaultZoom,
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: cfg.config.azureMapsSubscriptionKey
    },
    pixelRatio: window.devicePixelRatio || 1,
    enableAccessibility: false,
    style: 'road'
  })

  map.events.add('ready', async () => {
    const POIStore = usePOIStore()
    const networkStore = useNetworkStore()

    await buildAndAddCustomMapIcons(map!)

    buildAndAddGenericMapDataSources(map!)
    buildAndAddGenericLayers(
      map!,
      structuresDataSource,
      locationsDataSource,
      routeDataSource,
      entryExitRouteDataSOurce,
      startPointDataSource,
      endPointDataSource,
      routeStructuresDataSource,
      routeLocationsDataSource,
      routeRoadWorksDataSource,
      debugRoutePointsDataSource
    )

    await networkStore.fetchNetworks()

    networkStore.networks.forEach((network: Network) => {
      addNetworkLayersAndDataSources(network.id)
    })

    if (POIStore.POIsVisible) {
      await getPOIsAndAddToMap() // add global POI's
    }

    map!.events.add('click', (event: MapMouseEvent) => {
      mapCoordinateSelectionStore.setSelectedCoordinates(event.position![0], event.position![1])
    })

    if (debugStore.isDebuggingEnabled && debugStore.trackCoordinates) {
      map!.events.add('mousemove', (event: MapMouseEvent) => {
        debugStore.latitude = event.position![1].toFixed(6)
        debugStore.longitude = event.position![0].toFixed(6)
      })
    }
  })

  new Konami(() => toggleStyle())
}

export function resetMap() {
  map!.setCamera({
    center: [defaultCenterLongitude, defaultCenterLatitude],
    zoom: defaultZoom
  })
}

export async function addNetworkToMap(networkId: number) {
  const networkStore = useNetworkStore()
  const POIStore = usePOIStore()

  const network = networkStore.networks.find((n) => n.id === networkId)

  const dataSources = networkDataSources[networkId]

  if (dataSources && network) {
    if (network.geoData && network.geoData.features && network.geoData.features.length > 0) {
      await renderNetworkSegments(networkId, network, dataSources)
    }

    if (POIStore.POIsVisible) {
      renderNetworkPOIs(networkId)
    }
  }
}

export function addRouteToMap(route: Route) {
  if (route && route.features && route.features.length > 0) {
    const debugStore = useDebugStore()

    addRouteFeaturesToMap(route)

    if (route.startPointFeature) {
      if (debugStore.isDebuggingEnabled) {
        route.startPointFeature.properties = {
          ...route.startPointFeature.properties,
          desc: `${route.startPointFeature.properties.desc}:
          ${route.startPointFeature.geometry.coordinates[1]} - ${route.startPointFeature.geometry.coordinates[0]}`
        }
      }
      startPointDataSource.add(route.startPointFeature)
    }

    if (route.endPointFeature) {
      if (debugStore.isDebuggingEnabled) {
        route.endPointFeature.properties = {
          ...route.endPointFeature.properties,
          desc: `${route.endPointFeature.properties.desc}:
          ${route.endPointFeature.geometry.coordinates[1]} - ${route.endPointFeature.geometry.coordinates[0]}`
        }
      }
      endPointDataSource.add(route.endPointFeature)
    }

    if (route.entryLineFeature && route.exitLineFeature) {
      entryExitRouteDataSOurce.add([route.entryLineFeature, route.exitLineFeature])
    }

    if (route.pois && route.pois.length > 0) {
      const structures = filterStructures(route.pois)
      const locations = filterLocations(route.pois)

      addPOIsToMap(structures, locations, undefined, true)
    }

    if (route.roadWorks && route.roadWorks.length > 0) {
      addRoadWorksToMap(route.roadWorks, true)
    }
  }
}

function addRouteFeaturesToMap(route: Route) {
  fixRouteSegment(route.startPointFeature, route.entryLineFeature, route.features, 'entry')
  fixRouteSegment(route.endPointFeature, route.exitLineFeature, route.features, 'exit')

  const debugStore = useDebugStore()

  if (debugStore.isDebuggingEnabled && debugStore.showRouteSegments) {
    const points: any[] = []
    const outerPoints: any[] = []

    route.features.forEach((f) => {
      f.geometry.coordinates.forEach((coordinate, index) => {
        const point = new atlas.data.Point([coordinate[0], coordinate[1]])
        const feature = new atlas.data.Feature(point, {
          desc: `<br/>${coordinate[1]} - ${coordinate[0]}`
        })

        if (index === 0 || index === f.geometry.coordinates.length - 1) {
          outerPoints.push(feature)
        } else {
          points.push(feature)
        }
      })
    })

    debugRoutePointsDataSource.add(points)
    debugRouteOuterPointsDataSource.add(outerPoints)
  }

  routeDataSource.add(route.features)
}

function fixRouteSegment(
  startOrEndPointFeature: GeoJsonPointFeature,
  entryOrExitLineFeature: GeoJsonLineFeature,
  routeFeatures: GeoJsonLineFeature[],
  pointType: PointType
) {
  let featureIndex = -1
  let lineStringIndex = -1

  // Get coordinates of point where entry or exit line intersect with route
  const routeIntersectionPoint = getIntersectionPoint(
    startOrEndPointFeature,
    entryOrExitLineFeature
  )

  if (routeIntersectionPoint) {
    routeFeatures.forEach((routeFeature, index) => {
      const indexOfLineStringContainingPoint = getIndexOfLineStringIntersectingWithPoint(
        routeIntersectionPoint!,
        routeFeature.geometry.coordinates
      )
      if (indexOfLineStringContainingPoint !== -1) {
        featureIndex = index
        lineStringIndex = indexOfLineStringContainingPoint
      }
    })

    if (featureIndex === -1) {
      addCustomSegments(pointType, routeFeatures, routeIntersectionPoint)
    } else {
      removeObsoleteSegments(
        pointType,
        routeFeatures[featureIndex],
        lineStringIndex,
        routeIntersectionPoint
      )
    }
  }
}

function getIntersectionPoint(pointFeature: GeoJsonPointFeature, lineFeature: GeoJsonLineFeature) {
  return lineFeature.geometry.coordinates.find(
    (coordinates: [number, number]) =>
      pointFeature.geometry.coordinates[0] !== coordinates[0] &&
      pointFeature.geometry.coordinates[1] !== coordinates[1]
  )
}

function removeObsoleteSegments(
  pointType: PointType,
  feature: GeoJsonLineFeature,
  indexOfLineContainingPoint: number,
  startOrEndPointCoordinates: [number, number]
) {
  switch (pointType) {
    case 'entry':
      // Remove the obsolete segments
      feature.geometry.coordinates = feature.geometry.coordinates.slice(indexOfLineContainingPoint)
      // Cut first segment that is left over
      feature.geometry.coordinates[0] = startOrEndPointCoordinates
      break
    case 'exit':
      feature.geometry.coordinates = feature.geometry.coordinates.slice(
        0,
        indexOfLineContainingPoint + 2
      )
      break
    default:
      break
  }
}

function addCustomSegments(
  pointType: PointType,
  features: GeoJsonLineFeature[],
  point: [number, number]
) {
  switch (pointType) {
    case 'entry':
      features.at(0)!.geometry.coordinates.unshift(point)
      break
    case 'exit':
      features.at(-1)!.geometry.coordinates.push(point)
      break
    default:
      break
  }
}

function addPOIsToMap(
  structures: POI[],
  locations: POI[],
  networkId?: number,
  routePOIs: boolean = false
) {
  const structureFeatures: Feature<
    atlas.data.Point,
    {
      id: number
      type: POIType
      text?: string
      title: string
      desc: string
      height?: string
    }
  >[] = []

  for (const structure of structures) {
    const point = new atlas.data.Point([
      parseFloat(structure.longitude),
      parseFloat(structure.latitude)
    ])
    structureFeatures.push(
      new atlas.data.Feature(point, {
        id: structure.id,
        type: POIType.Structure,
        text: structure.height,
        title: structure.name,
        desc: structure.description,
        height: structure.height
      })
    )
  }

  if (!networkId) {
    if (routePOIs) {
      routeStructuresDataSource.add(structureFeatures)
    } else {
      structuresDataSource.add(structureFeatures)
    }
  } else {
    const dataSources = networkDataSources[networkId]
    dataSources.structuresDataSource.add(structureFeatures)
  }

  const locationFeatures: Feature<
    atlas.data.Point,
    {
      id: number
      type: POIType
      title: string
      desc: string
    }
  >[] = []

  for (const location of locations) {
    const point = new atlas.data.Point([
      parseFloat(location.longitude),
      parseFloat(location.latitude)
    ])
    locationFeatures.push(
      new atlas.data.Feature(point, {
        id: location.id,
        type: POIType.Location,
        title: location.name,
        desc: location.description
      })
    )
  }

  if (!networkId) {
    if (routePOIs) {
      routeLocationsDataSource.add(locationFeatures)
    } else {
      locationsDataSource.add(locationFeatures)
    }
  } else {
    const dataSources = networkDataSources[networkId]
    dataSources.locationsDataSource.add(locationFeatures)
  }
}

export async function getPOIsAndAddToMap(networkId?: number) {
  const POIStore = usePOIStore()
  const networkStore = useNetworkStore()

  if (!POIStore.POIs?.get(networkId)?.length) {
    await POIStore.fetchPOIs(networkId)
  }

  const structures = POIStore.getStructures(networkId)
  const locations = POIStore.getLocations(networkId)

  //move layers so that layers are in correct sequence
  networkStore.selectedNetworks.forEach((selectedNetworkId: number) => {
    map!.layers.move(`${structuresLayerId}-${selectedNetworkId}`, routeLayerId)
    map!.layers.move(`${locationsLayerId}-${selectedNetworkId}`, routeLayerId)
  })

  removePOIsFromMap(networkId)
  addPOIsToMap(structures, locations, networkId)
}

function addRoadWorksToMap(roadWorksList: RoadWorks[], routeRoadWorks: boolean = false) {
  const roadWorksFeatures: Feature<
    atlas.data.Point,
    {
      id: number
      type: POIType
      text?: string
      title: string
      desc: string
    }
  >[] = []

  for (const roadWorks of roadWorksList) {
    const point = new atlas.data.Point([
      parseFloat(roadWorks.longitude),
      parseFloat(roadWorks.latitude)
    ])

    let title = roadWorks.owner

    if (roadWorks.startDate || roadWorks.endDate) {
      title += ' ('
      title += roadWorks.startDate ? formatDate(roadWorks.startDate) : ''
      title += ' - '
      title += roadWorks.endDate ? formatDate(roadWorks.endDate) : ''
      title += ')'
    }

    roadWorksFeatures.push(
      new atlas.data.Feature(point, {
        id: roadWorks.id,
        type: POIType.RoadWorks,
        title: title,
        desc: `${roadWorks.title}<br/><br/>${roadWorks.details}`
      })
    )
  }

  if (routeRoadWorks) {
    routeRoadWorksDataSource.add(roadWorksFeatures)
  } else {
    roadWorksDataSource.add(roadWorksFeatures)
  }
}

export async function getRoadWorksAndAddToMap() {
  const roadWorkStore = useRoadWorksStore()
  const networkStore = useNetworkStore()

  if (!roadWorkStore.roadWorks.length) {
    await roadWorkStore.fetchRoadworks()
  }

  //move layers so that layers are in correct sequence
  networkStore.selectedNetworks.forEach(() => {
    map!.layers.move(roadWorksLayerId, routeLayerId)
    map!.layers.move(`${roadWorksLayerId}-clustered`, roadWorksLayerId)
  })

  removeRoadWorksFromMap()
  addRoadWorksToMap(roadWorkStore.roadWorks)
}

async function renderNetworkSegments(
  networkId: number,
  network: Network,
  dataSources: NetworkDataSources
) {
  const networkStore = useNetworkStore()
  const debugStore = useDebugStore()

  const chunkSize = 10000

  networkStore.selectedNetworks.forEach((selectedNetworkId: number) => {
    map!.layers.move(`${segmentsLayerId}-${selectedNetworkId}`, `${segmentsLayerId}-${networkId}`)
    map!.layers.move(roadWorksLayerId, `${segmentsLayerId}-${selectedNetworkId}`)
    map!.layers.move(`${roadWorksLayerId}-clustered`, roadWorksLayerId)
  })

  for (let i = 0; i < network.geoData!.features.length; i += chunkSize) {
    const features = network.geoData!.features.slice(i, i + chunkSize)
    dataSources.networkDataSource.add(features)

    await delay()
  }

  if (debugStore.isDebuggingEnabled && debugStore.showNetworkFileMetadata) {
    const outerPoints: any[] = []

    network.geoData!.features.forEach((f) => {
      f.geometry.coordinates.forEach((coordinate, index) => {
        const point = new atlas.data.Point([coordinate[0], coordinate[1]])
        const feature = new atlas.data.Feature(point, {
          title: f.metadata.name,
          desc: `
                    Lengte: ${f.metadata.length}
                    Breedte: ${f.metadata.width}
                    Hoogte: ${f.metadata.height}
                    Gewicht: ${f.metadata.weight}
                    `
        })

        if (index === 0 || index === f.geometry.coordinates.length - 1) {
          outerPoints.push(feature)
        }
      })
    })

    dataSources.debugNetworkPointsDataSource.add(outerPoints)
  }
}

function renderNetworkPOIs(networkId: number) {
  const POIStore = usePOIStore()
  const networkStore = useNetworkStore()

  networkStore.selectedNetworks.forEach((selectedNetworkId: number) => {
    map!.layers.move(
      `${structuresLayerId}-${selectedNetworkId}`,
      `${structuresLayerId}-${networkId}`
    )
    map!.layers.move(`${locationsLayerId}-${selectedNetworkId}`, `${locationsLayerId}-${networkId}`)
  })

  const POIs = POIStore.POIs.get(networkId)

  if (POIs) {
    const structures = filterStructures(POIs)
    const locations = filterLocations(POIs)
    addPOIsToMap(structures, locations, networkId)
  }
}

export function removeNetworkFromMap(networkId: number) {
  const dataSources = networkDataSources[networkId]

  if (dataSources) {
    dataSources.networkDataSource.clear()
    dataSources.structuresDataSource.clear()
    dataSources.locationsDataSource.clear()
    dataSources.debugNetworkPointsDataSource.clear()
  }
}

export function removeRouteFromMap() {
  routeDataSource.clear()
  startPointDataSource.clear()
  endPointDataSource.clear()
  entryExitRouteDataSOurce.clear()
  routeStructuresDataSource.clear()
  routeLocationsDataSource.clear()
  routeRoadWorksDataSource.clear()
  debugRouteOuterPointsDataSource.clear()
  debugRoutePointsDataSource.clear()

  popup.close()
}

export function removePOIsFromMap(networkId?: number) {
  if (!networkId) {
    structuresDataSource.clear()
    locationsDataSource.clear()
  } else {
    const dataSources = networkDataSources[networkId]

    if (dataSources) {
      dataSources.structuresDataSource.clear()
      dataSources.locationsDataSource.clear()
    }
  }

  popup.close()
}

export function removeRoadWorksFromMap() {
  roadWorksDataSource.clear()
  popup.close()
}

export function addNetworkLayersAndDataSources(networkId: number) {
  if (map) {
    const networkStore = useNetworkStore()
    const network = networkStore.networks.find((n) => n.id === networkId)

    if (network) {
      const dataSources: NetworkDataSources = buildAndAddNetworkDataSources(map!)
      networkDataSources[networkId] = dataSources

      buildAndAddNetworkLayers(
        map!,
        networkId,
        network.color,
        dataSources.networkDataSource,
        dataSources.structuresDataSource,
        dataSources.locationsDataSource,
        dataSources.debugNetworkPointsDataSource
      )
    }
  }
}

export function removeNetworkLayersAndDataSources(networkId: number) {
  removeNetworkLayers(map!, networkId)

  removeNetworkDataSources(map!, networkDataSources[networkId])
  delete networkDataSources[networkId]
}

export function zoom(multiplier: number) {
  const currentZoom = map!.getCamera().zoom!
  const newZoom = currentZoom * multiplier
  map!.setCamera({
    zoom: newZoom,
    type: 'ease',
    duration: 200
  })
}

export function zoomAndCenterOnLocation(longitude: number, latitude: number, zoom: number) {
  map!.setCamera({
    center: [longitude, latitude],
    zoom: zoom
  })
  map!.triggerRepaint()
}

function toggleStyle() {
  map?.setStyle({
    style: map?.getStyle().style === 'road' ? 'night' : 'road'
  })
}
