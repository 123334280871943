import { defineStore } from 'pinia'
import { apiClient } from '@/services/api.service'
import { ENDPOINTS } from '@/endpoints'
import { computed, ref, type Ref } from 'vue'
import type { POI } from '@/contracts/POI'
import { filterLocations, filterStructures } from '@/utils/POI-utils'

export const usePOIStore = defineStore('POI', () => {
  const _POIs: Ref<Map<number | undefined, POI[]>> = ref(new Map<number | undefined, POI[]>())
  const POIs = computed(() => _POIs.value)

  const _POIsToIgnore: Ref<number[]> = ref([])
  const POIsToIgnore = computed(() => _POIsToIgnore)

  const _POIsVisible = ref(false)
  const POIsVisible = computed({
    get() {
      return _POIsVisible.value
    },
    set(isVisible: boolean) {
      _POIsVisible.value = isVisible
    }
  })

  async function fetchPOIs(networkId?: number) {
    const response = await apiClient.get(ENDPOINTS.POI(), {
      params: { networkId }
    })
    _POIs.value.set(networkId, response.data)
  }

  async function uploadPOIs(file: File, networkId?: number): Promise<void> {
    const formData = new FormData()
    formData.append('file', file)

    if (networkId) {
      formData.append('networkId', networkId.toString())
    }

    const response = await apiClient.post<POI[]>(ENDPOINTS.POI(), formData)

    const newPOIs = new Map(_POIs.value)
    newPOIs.set(networkId, response.data)
    _POIs.value = newPOIs
  }

  function getStructures(networkId?: number) {
    return filterStructures(_POIs.value.get(networkId))
  }

  function getLocations(networkId?: number) {
    return filterLocations(_POIs.value.get(networkId))
  }

  function removePOIs(networkId: number) {
    const newPOIs = new Map(_POIs.value)
    newPOIs.delete(networkId)
    _POIs.value = newPOIs
  }

  function setPOIsVisibility(isVisible: boolean) {
    _POIsVisible.value = isVisible
  }

  function ignorePOI(id: number) {
    _POIsToIgnore.value.push(id)
  }

  function dontIgnorePOI(id: number) {
    const index = _POIsToIgnore.value.indexOf(id)

    if (index !== -1) {
      _POIsToIgnore.value.splice(index, 1)
    }
  }

  function clearPOIsToIgnore() {
    _POIsToIgnore.value = []
  }

  return {
    POIs,
    POIsToIgnore,
    POIsVisible,
    uploadPOIs,
    fetchPOIs,
    getStructures,
    getLocations,
    removePOIs,
    setPOIsVisibility,
    ignorePOI,
    dontIgnorePOI,
    clearPOIsToIgnore
  }
})
