<script lang="ts" setup>
import Button from 'primevue/button'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

function logIn() {
  // TODO
}
</script>
<template>
  <main class="flex flex-column justify-content-center align-items-center">
    <h2>{{ t('logged-out.message') }}</h2>
    <Button
      label="Log in"
      icon="pi pi-sign-in"
      class="primary-button p-button-raised"
      @click="logIn"
    />
  </main>
</template>

<style></style>
