import { defineStore } from 'pinia'
import { computed, type Ref, ref } from 'vue'
import type { Route } from '@/contracts/route'
import { apiClient } from '@/services/api.service'
import { ENDPOINTS } from '@/endpoints'

export const useRouteStore = defineStore('route', () => {
  const _route: Ref<Route | undefined> = ref()
  const route = computed(() => _route.value)
  const isRouteAvailable = computed(() => _route.value?.features)

  const _isGeneratingRoute = ref(false)
  const isGeneratingRoute = computed(() => _isGeneratingRoute.value)

  async function calculateRoute(
    networkId: number,
    longitudeStart: number,
    latitudeStart: number,
    longitudeEnd: number,
    latitudeEnd: number,
    length: number,
    width: number,
    height: number,
    weight: number,
    POIsToIgnore: number[],
    roadWorksToIgnore: number[]
  ) {
    const params = {
      longitudeStart,
      latitudeStart,
      longitudeEnd,
      latitudeEnd,
      length,
      width,
      height,
      weight,
      POIsToIgnore,
      roadWorksToIgnore
    }

    try {
      _isGeneratingRoute.value = true

      const response = await apiClient.get<Route>(ENDPOINTS.routes(networkId), {
        params,
        paramsSerializer: {
          indexes: true // use brackets with indexes
        }
      })

      if (!response.data) {
        _isGeneratingRoute.value = false
        throw new Error(
          'Er kon geen route gegenereerd worden tussen de gekozen punten op het geselecteerde netwerk.'
        )
      }

      _route.value = response.data
    } finally {
      _isGeneratingRoute.value = false
    }
  }

  function clearRoute() {
    _route.value = undefined
  }

  return { route, calculateRoute, clearRoute, isRouteAvailable, isGeneratingRoute }
})
