<script setup lang="ts">
import {useNetworkStore} from '@/stores/network'
import {ref} from 'vue'
import {showInfoToast, showSuccessToast} from '@/composables/toast'
import {usePOIStore} from '@/stores/POI'
import {showConfirmationDialog} from '@/composables/confirmation'
import NetworkEditor from '@/components/NetworkEditor.vue'
import {
  addNetworkLayersAndDataSources,
  getPOIsAndAddToMap,
  removeNetworkLayersAndDataSources
} from '@/services/map.service'
import POIUpload from '@/components/POIUpload.vue'

const networkStore = useNetworkStore()
const POIStore = usePOIStore()

const isUploadingNetworkFiles = ref(false)
const isUploadingPOIFiles = ref(false)
const isNewNetworkDialogVisible = ref(false)
const isUploadDialogVisible = ref(false)

function toggleNewNetworkDialog(visible: boolean) {
  isNewNetworkDialogVisible.value = visible
}

function toggleUploadDialog(visible: boolean) {
  isUploadDialogVisible.value = visible
}

async function uploadPOIs(file: File, networkId?: number) {
  isUploadingPOIFiles.value = true

  try {
    await POIStore.uploadPOIs(file, networkId)

    if (POIStore.POIsVisible) {
      await getPOIsAndAddToMap(networkId)
    }
    showSuccessToast(`POI's zijn succesvol geüpload`)
  } catch (error) {
    console.error('Error uploading POI file', error)
  } finally {
    isUploadingPOIFiles.value = false
  }
}

async function uploadFile(networkId: number, file: File) {
  isUploadingNetworkFiles.value = true

  try {
    await networkStore.uploadNetworkFile(networkId, file)

    showInfoToast(
        'Het bestand is succesvol geüpload. Wanneer het verwerkt is, verschijnt het in de lijst met bestanden.'
    )
  } finally {
    isUploadingNetworkFiles.value = false
  }
}

async function deleteFile(networkId: number, fileId: number) {
  const network = networkStore.networks.find(n => n.id === networkId)
  const isLastFile = network && network.files && network.files.length === 1
  const message = isLastFile ?
      'Het bestand, alle bijhorende data en het netwerk zullen worden verwijderd.'
      : 'Het bestand en alle bijhorende data zal worden verwijderd.'

  await showConfirmationDialog(
      'Wilt u het bestand verwijderen?',
      message,
      'Verwijderen'
  )

  try {
    await networkStore.deleteNetworkFile(networkId, fileId)

    if(isLastFile) {
      await networkStore.deleteNetwork(networkId)
      isUploadDialogVisible.value = !isUploadDialogVisible.value
      showSuccessToast(`Het bestand en het netwerk zijn succesvol verwijderd`)
    } else {
      await networkStore.networkUpdated(networkId)
      showSuccessToast(`Het bestand is succesvol verwijderd`)
    }
  } catch (error) {
    console.error('Error deleting file', error)
  }
}

async function createNetwork(file: File, networkName: string) {
  if (!networkName) {
    return
  }

  isUploadingNetworkFiles.value = true

  try {
    const network = await networkStore.createNetwork(file, networkName)
    addNetworkLayersAndDataSources(network.id)

    isNewNetworkDialogVisible.value = false

    showInfoToast(
        'Het netwerk is succesvol geüpload. Binnen enkele ogenblikken kan je het netwerk gebruiken.'
    )
  } finally {
    isUploadingNetworkFiles.value = false
  }
}

async function deleteNetwork(networkId: number) {
  await showConfirmationDialog(
      'Wilt u het netwerk verwijderen?',
      'Het netwerk zal verdwijnen uit de lijst en alle data wordt verwijderd',
      'Verwijderen'
  )

  try {
    await networkStore.deleteNetwork(networkId)

    POIStore.removePOIs(networkId)
    removeNetworkLayersAndDataSources(networkId)

    showSuccessToast(`Het netwerk is succesvol verwijderd`)
  } catch (error) {
    console.error('Error deleting network', error)
  }
}

async function editNetwork(networkId: number) {
  const network = networkStore.networks!.find((n) => n.id === networkId)

  if (network && (!network.files || network.files.length === 0)) {
    await networkStore.fetchNetworkFiles(networkId)
  }
  isUploadDialogVisible.value = !isUploadDialogVisible.value
}
</script>

<template>
  <span class="title text-2xl font-semibold pb-2">Uploaden informatie</span>
  <POIUpload
      :networks="networkStore.networks"
      @upload="uploadPOIs"
      :is-uploading-files="isUploadingPOIFiles"
  />
  <NetworkEditor
      :networks="networkStore.networks"
      @create-network="createNetwork"
      @edit-network="editNetwork"
      @delete-network="deleteNetwork"
      @upload-file="uploadFile"
      @delete-file="deleteFile"
      @toggle-new-network-dialog="toggleNewNetworkDialog"
      @toggle-upload-dialog="toggleUploadDialog"
      :show-new-network-dialog="isNewNetworkDialogVisible"
      :show-upload-dialog="isUploadDialogVisible"
      :is-uploading-files="isUploadingNetworkFiles"
  />
</template>

<style scoped lang="scss">
.title {
  color: #fbc735;
  border-bottom: 5px solid #fbc735;
}

:deep(.p-datatable-thead th) {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
</style>
