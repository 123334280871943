<script setup lang="ts">
import { useMapCoordinateSelectionStore } from '@/stores/mapCoordinateSelection'
import { MenuItems, useMenuStore } from '@/stores/menu'
import { watch } from 'vue'

defineProps({
  visible: Boolean
})

const mapCoordinateSelectionStore = useMapCoordinateSelectionStore()
const menuStore = useMenuStore()

watch(
  () => menuStore.activeMenuItem,
  (newValue) => {
    if (newValue != MenuItems.Map) {
      mapCoordinateSelectionStore.prohibitSetCoordinates()
    }
  }
)
</script>

<template>
  <transition name="slide-fade">
    <div v-show="visible">
      <div
        v-if="
          mapCoordinateSelectionStore.isAllowedToSetCoordinates &&
          menuStore.activeMenuItem == MenuItems.Map
        "
        class="overlay-color fixed top-0 left-0 opacity-40 z-1 min-h-screen border-round-2xl z-4"
      />
      <div
        class="sidebar-expanded absolute flex-1 h-screen bg-white top-0 left-0 border-round-2xl py-4 pr-5 overflow-x-hidden z-3"
        :class="
          mapCoordinateSelectionStore.isAllowedToSetCoordinates
            ? 'overflow-y-hidden'
            : 'overflow-y-auto'
        "
      >
        <slot />
      </div>
    </div>
  </transition>
</template>

<style scoped lang="scss">
.sidebar-expanded {
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.25);
  padding-left: 6rem;
  width: var(--sidebar-expanded-width);
}

.slide-fade-enter-active {
  transition: all 0.2s;
}

.slide-fade-leave-active {
  transition: all 0.2s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.nav-link-icon {
  flex-shrink: 0;
}

.overlay-color {
  background-color: var(--black-color);
  width: var(--sidebar-expanded-width);
}
</style>
